import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Assume a different endpoint for fetching problems from the second database
export const fetchProblems = createAsyncThunk('fetchProblems', async () => {
    try {
        const response = await axios.get('/pre-potd'); // Endpoint for second database problems
        // console.log(response.data)
        return response.data;
    } catch (error) {
        throw error; // Throw an error for rejection in the async thunk
    }
});

const fetchProblemsSlice = createSlice({
    name: 'PotdProblems',
    initialState: {
        isLoading: false,
        data: [],
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProblems.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchProblems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchProblems.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        });
    },
});

export default fetchProblemsSlice.reducer;
