// Modal2.js
import React from 'react';

const Modal2 = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  const handleClose = (e) => {
    // Check if the click occurred on the close button or the wrapper
    if (e.target.classList.contains('close-button') || e.target.id === 'wrapper') {
      onClose();
    }
  };

  return (
    <div className='fixed inset-0 flex justify-center items-center' id='wrapper' onClick={handleClose}>
      <div className='fixed inset-0 bg-black bg-opacity-25'></div>
      <div className='relative z-10 bg-white p-6 rounded' onClick={(e) => e.stopPropagation()}>
        <button className='absolute top-2 right-2 text-black text-xl close-button' onClick={onClose}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal2;
