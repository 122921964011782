import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { resetUser } from '../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import PieChart from "../../components/Charts/PieChart";
import { useState, useEffect } from "react";
import "../../Styles/profile.css"
import HeatMap from "../../components/Charts/HeatMap";
import Loader from "../../components/Loader";
import DifficultyChart from "../../components/Charts/DifficultyChart";
const Profile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = useSelector((state) => state.user.user);
	const profile = JSON.parse(window.localStorage.getItem('PROFILE_PIC'));
	const [graphData, setGraphData] = useState({});
	const [heatMapData, setHeatMapData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const logout = () => {
		window.localStorage.clear();
		dispatch(resetUser());
		navigate("/");
		toast.success("Logout Successful!");
	};

	useEffect(() => {
		if (userData.length > 0 || Object.keys(userData).length > 0) {
			setIsLoading(false);
		}

		if (userData && userData.solvedQuestions) {
			let temp = {};
			let tempHeatMap = [];
			for (let key of userData.solvedQuestions) {
				if (temp[key.tags[0]] == null) {
					temp[key.tags[0]] = 1;
				} else {
					temp[key.tags[0]] += 1;
				}
			}

			for (let i = 0; i < 12; i++) {
				tempHeatMap.push({});
			}
			for (let key of userData.solvedQuestions) {
				let date = key.date_solved;
				if (!date) continue;
				let month = date.split("-")[1];
				let day = date.split("-")[2];
				if (tempHeatMap[month - 1][day] == null)
					tempHeatMap[month - 1][day] = 0;
				tempHeatMap[month - 1][day]++;
			}

			temp = Object.entries(temp).sort((a, b) => b[1] - a[1]).reduce((acc, [key, value]) => {
				acc[key] = value;
				return acc;
			}, {});

			setHeatMapData(tempHeatMap);
			setGraphData({ ...temp });
		}

		return () => {
			window.removeEventListener("scroll", appearOnScroll);
			window.removeEventListener("load", appearOnScroll);
		}
	}, [userData]);

	useEffect(() => {
		if (!isLoading) {
			window.addEventListener("scroll", appearOnScroll);
			window.addEventListener("load", appearOnScroll);
			window.scrollBy(0, 1);
		}
	}, [isLoading])

	function appearOnScroll() {
		const blocks = document.querySelectorAll(".profile-container .welcome-section,#heat-map-container, .pie-chart , #difficulty-chart-container");
		blocks.forEach(block => {
			let maxScroll = document.documentElement.scrollHeight - window.innerHeight - 20;
			if (block.getBoundingClientRect().top < window.innerHeight / 1.5 || window.scrollY >= maxScroll) {
				block.classList.add("appear");
			} else {
				block.classList.remove("appear");
			}
		})
	}

	return (
		isLoading ? <Loader /> : (
			<div className="profile-container">
				<button
					className="log-out-btn"
					onClick={logout}
				>
					Logout
				</button>
				<div className="welcome-section">
					<div className="image-container">
						<img
							src={profile}
							alt="profile"
						/>
					</div>
					<div className="welcome-section-info">
						<h1>
							Hello, <span style={{ color: "#00a3ff" }}>{userData.name}</span>
						</h1>
						<h2 >
							{userData.username}
						</h2>
						<div className="profile-stats">
							<table>
								<tr>
									<td>Total Questions Solved:</td>
									<td>{userData.solvedQuestions?.length}</td>
								</tr>
								<tr>
									<td>Bookmarked Questions:</td>
									<td>{userData.bookmarkedQuestions?.length}</td>
								</tr>
								<tr>
									<td>OA questions solved:</td>
									<td>{userData.solvedOAQuestions?.length}</td>
								</tr>
								<tr>
									<td>Most solved Tag:</td>
									<td>{graphData && Object.entries(graphData)[0] && Object.entries(graphData)[0][0]} ({graphData && Object.entries(graphData)[0] && Object.entries(graphData)[0][1]})</td>
								</tr>
								<tr>
									<td>Liked Questions</td>
									<td>{userData.reaction.length}</td>
								</tr>
								<tr>
									<td>DisLiked Questions</td>
									<td>0</td>
								</tr>
							</table>
						</div>

					</div>
				</div>
				{Object.keys(graphData).length === 0 ? <></> : <PieChart data={graphData} />}
				<DifficultyChart userData={userData} />
				<HeatMap heatMapData={heatMapData} />
				<footer>SENIOR</footer>
			</div>
		)
	)
};

export default Profile;
