import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchOAQuestions = createAsyncThunk('fetchOAQuestions', async (company)=>{
    const response = await axios.get(`/OA_questions/${company}`);
    console.log(company, response);
    return response.data;
})

const fetchOAQuestionsSlice = createSlice({
    name: 'CurrentOAQuestions',
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchOAQuestions.pending, (state, action)=>{
            state.isLoading = true;
        })
        builder.addCase(fetchOAQuestions.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchOAQuestions.rejected, (state, action)=>{
            console.log('Error', action.payload);
            state.isError = true;
        })
    }
})

export default fetchOAQuestionsSlice.reducer;