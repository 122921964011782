import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BeatLoader } from 'react-spinners'; // Import BeatLoader from react-spinners

const ShowContests = ({ platform, props, theme }) => {
    const [contestData, setContestData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        const fetchContests = async () => {
            try {
                const response = await axios.get(
                    `/showContests?platform=${platform}`
                );
                setContestData(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Set loading to false when request completes (whether successful or not)
            }
        };

        fetchContests();
    }, [platform]);

    return (
        <div className={theme === "dark" ? "dark:bg-dark3" : "bg-blue2 text-white min-h-screen"}>
            <div className="flex justify-center items-center h-full">
                {loading ? ( // Display loading indicator if loading is true
                    <div className="text-center mt-4">
                        <BeatLoader color={'#00ff00'} loading={loading} size={15} />
                    </div>
                ) : platform === '' ? (
                    <div className="bg-white border text-black border-black rounded-md p-4 font-bold text-center">
                        Click on Button to See Contests
                    </div>
                ) : contestData.length === 0 ? (
                    <div className="bg-white border text-black border-black rounded-md p-4 font-bold text-center">
                        No Contests are there. Please add them above.
                    </div>
                ) : (
                    <div className="flex flex-col items-center">
                        {contestData.map((contest, index) => (
                            <div key={contest.contestId} className="mb-2 w-full max-w-md">
                                <div className="rounded border border-black bg-gray-100 hover:bg-green-300 transition duration-300 ease-in-out">
                                    <Link
                                        to={`/AddEditorial/${contest.contestPlatform} contest/${contest.contestId}`}
                                        className="text-black font-bold hover:text-white block p-2 text-center"
                                    >
                                        <p className="text-center">{index + 1}. {contest.contestName}</p>
                                    </Link>
                                </div>
                                {/* Add additional contest details as needed */}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShowContests;
