import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./index.css";
import App from "./App";
import axios from "axios";
import ReactGA from "react-ga4"

ReactGA.initialize("G-5EX9RBK2K7")

ReactGA.send({
	hitType:"pageview",
	page:window.location.pathname,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

// axios.defaults.baseURL = 'http://localhost:8080';
axios.defaults.baseURL = 'https://ask-senior-server.vercel.app';
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
