import React from "react";
import loginWaveLight from "../../assets/waves/loginWaveLight.svg";
import loginWaveDark from "../../assets/waves/loginWaveDark.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import axios from "axios";
import { fetchUser } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Login = (props) => {
	const navigate = useNavigate();
	// Fetching current user from server
	const dispatch = useDispatch();
	
	const userData = useSelector((state) => state.user.user);
	//Redirect user if they're already logged in
	if (userData) {
		navigate("/");
	}

	//! Firebase auth
	const firebaseConfig = {
		apiKey: "AIzaSyAy0Yj6yW8cCsXEAcYafUrTn8Bmh-4llLs",
		authDomain: "senior-5c96a.firebaseapp.com",
		databaseURL: "https://senior-5c96a-default-rtdb.firebaseio.com",
		projectId: "senior-5c96a",
		storageBucket: "senior-5c96a.appspot.com",
		messagingSenderId: "652023010346",
		appId: "1:652023010346:web:fe74365dc105558c155f53",
		measurementId: "G-K75ZVNQTQ6"
	};
	
	const app = initializeApp(firebaseConfig);
	const auth = getAuth(app);
	
	const provider = new GoogleAuthProvider();
	
	const signInWithGoogle = () => {
		signInWithPopup(auth, provider)
			.then(async (result) => {
				try {
					const userData = {
						name: result.user.displayName,
						email: result.user.email,
					}
					window.localStorage.setItem('PROFILE_PIC', JSON.stringify(result.user.photoURL));
					const response = await axios.post('/user', {userData});
					if(response.data){
						window.localStorage.setItem('CURRENT_USER_DATA', JSON.stringify(response.data));
						dispatch(fetchUser());
						toast.success("Login Successful!");
						navigate("/");
					}
				} catch (error) {
					console.log("Something went wrong while saving userData to mongoDB")
				}
				localStorage.setItem("Email", result.user.email);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	
	return (
		<>
			<section
				className="px-[5%] m flex flex-col gap-5 items-center justify-center h-screen w-screen relative bg-cover bg-no-repeat bg-center"
				style={props.theme === "dark" ? { backgroundImage: `url(${loginWaveDark})` } : { backgroundImage: `url(${loginWaveLight})`}}
			>
                <h1 className="text-white bg-blue dark:bg-dark px-6 py-4 lg:px-8 lg:py-5 dark:text-blue rounded-xl text-2xl lg:text-4xl font-bold ">Senior</h1>
				<div
					className="flex flex-col items-center justify-center gap-7 w-[100%] h-[20%] lg:w-[35%] lg:h-[30%] drop-shadow-lg rounded-lg bg-blue dark:bg-dark"
				>
                <h1 className="text-white rounded-xl text-2xl font-semibold ">Continue with google</h1>
					<div className="bg-white cursor-pointer  px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150" 
					onClick={signInWithGoogle}
                    >
						<img
							className="w-6 h-6"
							src="https://www.svgrepo.com/show/475656/google-color.svg"
							loading="lazy"
							alt="google logo"
						>
						</img>
						<span>Login with Google</span>
					</div>
				</div>
			</section>
		</>
	);
};

export default Login;