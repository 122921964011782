import { React, useEffect } from "react";
import Section2 from "./Section2";
import OurStory from "./OurStory";
import WhySenior from "./WhySenior";
// import Courses from "./Courses";
import Hero from "./Hero";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";

const Home = (props) => {
	useEffect(() => {
		// Check if the URL contains a success parameter indicating a successful login
		if (window.location.href === "http://localhost:3000/loginsucess") {
			toast.success("Login Successful!");
		} else if (window.location.href === "https://asksenior.in/loginsucess") {
			toast.success("Login Successful!");
		}
	}, []);
	return (
		<>
			<Hero theme={props.theme} />
			<Section2 theme={props.theme} />
			<OurStory theme={props.theme} />
			<WhySenior theme={props.theme} />
			{/* <Courses theme={props.theme} /> */}
			<Footer theme={props.theme} />
		</>
	);
};

export default Home;
