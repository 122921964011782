import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendOAQuestions = createAsyncThunk('sendOAQuestions', async (data)=>{
    // console.log(data); 
    const response = await axios.post('/OA_questions',{
            questions: data.questions,
            company: data.company,
            difficulty: data.difficulty,
        }
    );
    // console.log(response);
    return response.json;
})

const sendOAQuestionsSlice = createSlice({
    name: 'OA_questions',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: (builder)=>{
        builder.addCase(sendOAQuestions.pending, (state, action)=>{
            state.isLoading = true;
        })
        builder.addCase(sendOAQuestions.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.data = action.payload;
        })
        builder.addCase(sendOAQuestions.rejected, (state, action)=>{
            console.log('Error', action.payload);
            state.isError = true;
        })
    }
})

export default sendOAQuestionsSlice.reducer;