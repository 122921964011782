import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const tagsProgress = createAsyncThunk('tagsProgress', async (userID)=>{
    const response = await axios.get(`/tagsprogress/${userID}`);
    window.localStorage.setItem('ALL_TAG_PERCENT', JSON.stringify(response.data));
    return response.data;
})

const TagsProgressSlice = createSlice({
    name: 'tagsProgress',
    initialState: {
        isLoading: false,
        data: '',
        isError: false
    },
})
export default TagsProgressSlice.reducer;