// import { initializeApp } from "firebase/app";
// import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
// import axios from "axios";
// // import { useHistory } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";


// const firebaseConfig = {
//     apiKey: "AIzaSyAy0Yj6yW8cCsXEAcYafUrTn8Bmh-4llLs",
//     authDomain: "senior-5c96a.firebaseapp.com",
//     databaseURL: "https://senior-5c96a-default-rtdb.firebaseio.com",
//     projectId: "senior-5c96a",
//     storageBucket: "senior-5c96a.appspot.com",
//     messagingSenderId: "652023010346",
//     appId: "1:652023010346:web:fe74365dc105558c155f53",
//     measurementId: "G-K75ZVNQTQ6"
// };

// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);

// const provider = new GoogleAuthProvider();

// export const signInWithGoogle = () => {
//     const navigate = useNavigate();
// 	signInWithPopup(auth, provider)
// 		.then(async (result) => {
//             try {
//                 const userData = {
//                     name: result.user.displayName,
//                     email: result.user.email,
//                 }
//                 const response = await axios.post('/user', {userData});
//                 if(response.data){
//                     window.localStorage.setItem('CURRENT_USER_DATA', JSON.stringify(response.data));
//                     navigate("/");
//                 }
//             } catch (error) {
//                 console.log("Something went wrong while saving userData to mongoDB")
//             }
//             localStorage.setItem("Email", result.user.email);
// 		})
// 		.catch((error) => {
// 			console.log(error);
// 		});
// };

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAy0Yj6yW8cCsXEAcYafUrTn8Bmh-4llLs",
    authDomain: "senior-5c96a.firebaseapp.com",
    databaseURL: "https://senior-5c96a-default-rtdb.firebaseio.com",
    projectId: "senior-5c96a",
    storageBucket: "senior-5c96a.appspot.com",
    messagingSenderId: "652023010346",
    appId: "1:652023010346:web:fe74365dc105558c155f53",
    measurementId: "G-K75ZVNQTQ6"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };