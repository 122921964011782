import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const allProgress = createAsyncThunk('allProgress', async (userID)=>{
    const response = await axios.get(`/allprogress/${userID}`);
    await window.localStorage.setItem('ALL_PERCENT', JSON.stringify(response.data));
    return response.data;
})

const allProgressSlice = createSlice({
    name: 'allProgress',
    initialState: {
        isLoading: false,
        data: '',
        isError: false
    },
})
export default allProgressSlice.reducer;