import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HintSection from './HintSection';
import TagsSection from './TagsSection';
import VideoModal from '../components/VideoModal';
import QuillEditorTextModal from './QuillEditorTextModal';
import LinkModal from './LinkModal'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { BeatLoader } from 'react-spinners';

const ShowProblems = (props) => {
  const getProblemLetter = (index) => {
    return String.fromCharCode('A'.charCodeAt(0) + index);
  };

  const { contestPlatform, contestId } = useParams();
  const [contestData, setContestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const [problemName, setProblemName] = useState('');
  const [selectedHint, setSelectedHint] = useState('Problem c');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [isEditorial, setIsEditorial] = useState(false);
  const [contestName, setContestName] = useState('');
  const [newLink, setNewLink] = useState('');
  const [first, setFirst] = useState(false);

  const userData=useSelector((state)=>state.user.user);

  const handleVideoSolutionClick = () => {
    setShowVideoModal(true);
  };

  const handleAddLinkClick = () => {
    setShowLinkModal(true);
  }

  const toggleAddLinkModal = () => {
    setShowLinkModal((prev) => !prev);
  };

  const handleEditorialClick = () => {
    setIsEditorial(true);
    setShowEditorModal(true);
  };


  const handleEditorModalClose = () => {
    setShowEditorModal(false);
  };

  const handleDiscussClick = () => {
    // Open the specified WhatsApp group link in a new tab
    window.open('https://chat.whatsapp.com/HUwWZaokuyOGGNRSgGMOpz', '_blank');
  };

  const addLink = async () => {
    setLoading(true);
    try {
      if (newLink.trim() !== '') {
        const trimmedLink = newLink.trim();

        await axios.post('/addVideo', {
          contestPlatform,
          contestId,
          questionNo: getProblemLetter(selectedProblem),
          videoLink: trimmedLink
        });
        setNewLink('');
        toast.success('Link added successfully');
        setShowVideoModal(false);

      }
    }
    catch (error) {
      console.error('Error adding Link:', error);
      toast.error('Error adding Link');
    }
    finally{
      setLoading(false);
    }
  }


  const handleProblemClick = (index, name) => {
    if (selectedProblem === index) {
      // If the problem is already selected, deselect it
      setSelectedProblem(null);
      setProblemName('');
    } else {
      // If a different problem is selected or no problem is selected, select this problem
      setSelectedProblem(index);
      setProblemName(name);
      setSelectedHint('Problem c');
    }
  };

  useEffect(() => {
    const fetchContestDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/showQuestions?contestPlatform=${contestPlatform}&contestId=${contestId}`
        );
        setContestData(response.data.problems);
        console.log(response.data.contestName);
        setContestName(response.data.contestName); // New state for contest name
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError('Error fetching data');
        setLoading(false);
      }
      finally{
        setLoading(false);
      }
    };
    fetchContestDetails();
  }, [contestPlatform, contestId]);

  useEffect(() => {
    setSelectedHint('Problem c');
  }, [selectedProblem]);
if(loading){
  <div className="flex justify-center items-center h-full" style={{ zIndex: 999 }}>
     <BeatLoader color={'##36d694'} loading={loading} size={30} />
   </div>

}

  return (
    <div>
      {loading ? (
     <div className="flex justify-center items-center h-full" style={{ zIndex: 999 }}>
     <BeatLoader color={'##36d694'} loading={loading} size={30} />
   </div>
      ) : (
        <section className="bg-blue w-screen h-[60%] fixed lg:h-screen z-5 lg:w-[35%] p-5 dark:bg-dark2 overflow-y-auto">
          <div className="bg-blue7 p-6 rounded-lg flex flex-col items-center justify-center mt-[10px] dark:bg-dark3">
            <h1 className="text-white text-lg lg:text-2xl font-semibold mb-3">
              <h1 className="text-3xl">
                {contestPlatform === 'Codechef'
                  ? `Codechef Starters ${contestId}`
                  : contestName}
              </h1>
              <h2 className="text-center pr-4">Problems</h2>
            </h1>
          </div>
          <div className="overflow-y-auto max-h-[calc(100%-15rem)]">
            {contestData.map((problem, index) => (
              <div
                key={index}
                onClick={() => {
                  handleProblemClick(index, problem.problemNames);
                  setFirst(true);
                }}
                className={`text-3xl cursor-pointer `}
              >
                <div className={`border-black border rounded-md p-2 hover:bg-green-500 ${selectedProblem === index && first ? 'bg-green-600 font-bold text-white' : 'bg-gray-200'}`}>
                  {problem.problemNames}
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={() => {
              const redirectUrl =
                contestPlatform === 'Codechef'
                  ? `https://www.codechef.com/START${contestId}D`
                  : `https://codeforces.com/contest/${contestId}`;
                  window.open(redirectUrl, '_blank');
            }}
            className={`w-full mt-4 p-2 cursor-pointer ${'bg-green-600 text-white'
              }`}
          >
            Go to Contest
          </button>
        </section>
      )}

      <section className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark overflow-y-auto">
        {selectedProblem !== null ? (
          <div className="flex items-center justify-between mt-6 lg:mt-[90px] w-[100%]">
            <div className="w-[100%] px-[10%] flex flex-col items-center justify-center gap-5 lg:gap-14 mt-[10px]">

            <Box
  border="2px solid grey"
  sx={{ bgcolor: 'white', maxHeight: '400px', textAlign: 'center', borderRadius: '10px', padding: '20px' }} // Added textAlign, borderRadius, and padding
>
  <h1 className="mx-auto text-black text-4xl lg:text-4xl font-semibold">
    {problemName}
  </h1>
  <button
    onClick={() => {
      const problemAlphabet = getProblemLetter(selectedProblem);
      const selectedProblemData = contestData[selectedProblem];
      const redirectUrl =
        contestPlatform === 'Codechef'
          ? selectedProblemData.problemUrl
          : `https://codeforces.com/contest/${contestId}/problem/${problemAlphabet}`;
          window.open(redirectUrl, '_blank');
    }}
    className="w-full bg-green-500 text-white p-2 mt-4 cursor-pointer rounded" // Updated button styles
  >
    Solve
  </button>
</Box>

              <HintSection
                contestPlatform={contestPlatform}
                contestId={contestId}
                questionNo={getProblemLetter(selectedProblem)}
                selectedHint={selectedHint}
                setSelectedHint={setSelectedHint}
              />
              <TagsSection
                contestPlatform={contestPlatform}
                contestId={contestId}
                questionNo={getProblemLetter(selectedProblem)}
                selectedHint={selectedHint}
                setSelectedHint={setSelectedHint}
              />
              <div className=" fixed bottom-0 flex gap-4  className={`w-full mt-4 p-2 cursor-pointer ${
                selectedProblem !== null ? 'bg-green-600 text-white' : 'bg-gray-300'
              }`}">
                <button
                  onClick={handleVideoSolutionClick}
                  className="w-full bg-green-500 text-white p-2 mt-4 cursor-pointer"
                >
                  Video Solution
                </button>
                {showVideoModal && (
                  <VideoModal
                    contestPlatform={contestPlatform}
                    contestId={contestId}
                    questionNo={getProblemLetter(selectedProblem)}
                    closeModal={() => setShowVideoModal(false)}
                    props={props}
                  />
                )}
                <button
                  variant="outlined"
                  colour="error"
                  onClick={handleEditorialClick}
                  className="w-full bg-green-500 text-white p-2 mt-4 cursor-pointer"
                >
                  Text Editorial
                </button>
                <button
                  onClick={handleDiscussClick}
                  className="w-full bg-green-500 text-white p-2 mt-4 cursor-pointer"
                >
                  Discuss
                </button>
                {userData.isAdmin ? (
                <>
                    <button
                        onClick={handleAddLinkClick}
                        className="w-full bg-green-500 text-white p-2 mt-4 cursor-pointer"
                    >
                        Add Link
                    </button>
                    {showLinkModal && (
                        <LinkModal
                            isOpen={showLinkModal}
                            onClose={() => setShowLinkModal(false)}
                            addLink={addLink}
                            setNewLink={setNewLink}
                            newLink={newLink}
                        />
                    )}
                </>
             ) : null} 
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark">
            <h1 className="text-white text-center text-lg lg:text-3xl font-bold mt-[7%] lg:mt-[25%] p-10 rounded-xl bg-blue dark:bg-dark3 dark:text-blue ">
              Select a Question <br /> to continue!
            </h1>
          </div>
        )}
        {showEditorModal && (
          <QuillEditorTextModal
            isVisible={showEditorModal}
            onClose={handleEditorModalClose}
            isEditorial={isEditorial}
            initialValue=""
            contestId={contestId}
            contestPlatform={contestPlatform}
            questionNo={getProblemLetter(selectedProblem)}
            onSubmit={() => {
              console.log('Data submitted');
              // You can add any additional logic after submitting data
            }}
            problemName={problemName}
          />
        )}
      </section>
    </div>
  );



};
export default ShowProblems
