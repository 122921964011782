import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { VscTriangleRight, VscTriangleDown } from 'react-icons/vsc';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import QuillEditorModal from './QuillEditorModal';
import DOMPurify from 'dompurify';
import Box from '@mui/material/Box';
import { BeatLoader } from 'react-spinners';
import { useSelector } from 'react-redux';

const HintSection = ({ contestPlatform, contestId, questionNo }) => {
  const [hints, setHints] = useState([]);
  const [expandedHints, setExpandedHints] = useState([]);
  const [isAddHintModalOpen, setAddHintModalOpen] = useState(false);
  const [newHintText, setNewHintText] = useState('');
  const [editingHintIndex, setEditingHintIndex] = useState(null);
  const [refreshHints, setRefreshHints] = useState(false);
  const [loadingHints, setLoadingHints] = useState(true); // Added loading state
  const userData=useSelector((state)=>state.user.user);
 

  useEffect(() => {
    fetchHints();
  }, [contestPlatform, contestId, questionNo, refreshHints]);

  const fetchHints = async () => {
    try {
      setLoadingHints(true); // Set loading state to true when making the request
      const response = await axios.get('/getHints', {
        params: {
          contestPlatform: contestPlatform,
          contestId: contestId,
          questionNo: questionNo,
        },
      });

      const fetchedHints = response.data.hints || [];
      setHints(fetchedHints);
      setExpandedHints(new Array(fetchedHints.length).fill(false));
    } catch (error) {
      console.error('Error fetching hints:', error);
    } finally {
      setLoadingHints(false); // Set loading state to false after the request completes
    }
  };

  const handleHintRefresh = () => {
    setRefreshHints(prevState => !prevState); // Toggle refreshHints to trigger hint refresh
  };

  const deleteHint = async (index) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this hint?');

    if (!isConfirmed) {
      return;
    }

    try {
      await axios.delete('/deleteHints', {
        data: {
          contestPlatform,
          contestId,
          questionNo,
          hintIndex: index,
        },
      });

      fetchHints();
    } catch (error) {
      console.error('Error deleting hint:', error);
    }
  };

  const toggleHint = (index) => {
    setExpandedHints((prev) => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const openAddHintModal = (doEdit, hintNo) => {
    setEditingHintIndex(doEdit ? hintNo : null);

    if (doEdit && hints[hintNo]) {
      setNewHintText(hints[hintNo]?.deltas || '');
    } else {
      setNewHintText('');
    }

    setAddHintModalOpen(true);
  };

  const closeAddHintModal = () => {
    setAddHintModalOpen(false);
    setEditingHintIndex(null);
    setNewHintText('');
  };

  useEffect(() => {
    // Clear hints state when a new question is selected
    setHints([]);
    setExpandedHints([]);
  }, [contestPlatform, contestId, questionNo]);

  return (
    <Box
      p={2}
      border="2px solid grey"
      sx={{
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
        maxHeight: '400px', // Increase the maximum height
        maxWidth: '800px', // Increase the maximum width
        width: '800px',
        height: '600px',
        alignItems: 'flex-start', // Align content at the top
        marginTop: '-50px'
      }}
    >
      {loadingHints && <BeatLoader color={'#00ff00'} loading={loadingHints} size={15} />} {/* Render BeatLoader while loadingHints is true */}
      <div style={{ overflowY: 'auto', width: '100%' }}>
        <span className="font-bold mr-2">Hints</span>
        {userData.isAdmin && (
          <button onClick={() => openAddHintModal(false)} className="text-blue-500 flex-col justify-center items-center">
            <IoIosAddCircle />
          </button>
        )} 

        {hints.length === 0 ? (
          <p>No hints available.</p>
        ) : (
          <>
            {hints.map((hint, index) => (
              <Box
                key={index}
                my={2}
                p={2}
                border="1px solid #ccc"
                borderRadius={4}
                bgcolor="#f9f9f9"
                width="100%"
              >
                <div className="hint-container">
                  <div className="hint-header flex items-center">
                    <button onClick={() => toggleHint(index)}>
                      {expandedHints[index] ? <VscTriangleDown /> : <VscTriangleRight />}
                    </button>
                    <span className="ml-2">Hint {index + 1}</span>
                    <div className="hint-actions ml-4">
                      {userData.isAdmin && (
                        <>
                          <button onClick={() => openAddHintModal(true, index)} className="text-blue-500 mr-2">
                            <FaEdit />
                          </button>
                          <button onClick={() => deleteHint(index)} className="text-red-500">
                            <MdDeleteForever />
                          </button>
                        </>
                       )} 
                    </div>
                  </div>
                  {expandedHints[index] && hint && hint.deltas && (
                    <div
                      className="hint-content mt-2 mr-2 border p-2 rounded bg-gray-100 text-black max-w-800"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(hint.deltas) }}
                    />
                  )}
                </div>
              </Box>
            ))}
          </>
        )}

        <QuillEditorModal
          onHintAdded={handleHintRefresh}
          isVisible={isAddHintModalOpen}
          onClose={closeAddHintModal}
          doEdit={editingHintIndex !== null}
          hintNo={editingHintIndex}
          initialValue={newHintText}
          contestPlatform={contestPlatform}
          contestId={contestId}
          questionNo={questionNo}
          setHints={setHints}
        />
      </div>
    </Box>
  );
};

export default HintSection;
