import React from "react";
import ProgressBar from "./ProgressBar";
import codeSVG from "../assets/codeCard.svg";
import { NavLink } from "react-router-dom";
import badge from "../assets/badge-png.png";

const LearnCard = (props) => {
  const { tagName, percentSolved, UserID, totalQuestions, solvedQuestions } = props;
  // <-------- Storting clicked Tag in Store ---------->
  const saveTag = (tag, percent) => {
    const TagProgress = {
      name: tag,
      percent: percent,
      total: totalQuestions,
      solved: solvedQuestions,
    };
    window.localStorage.setItem(
      "SELECTED_TAG_DATA",
      JSON.stringify(TagProgress)
    );
  };

  const CardProgressBarCSS = {
    bgLine: "bg-darkBlue px-0.5 h-3 md:h-4 md:px-1 dark:bg-blue",
    frontLine: "bg-blue h-2 md:h-2 dark:bg-darkBlue",
    text: "text-darkBlue  text-md font-semibold mt-0.5 dark:text-blue2",
  };
  return (
    <>
      {UserID ? (
        <>
          <NavLink
            to="/solve"
            onClick={() => {
              saveTag(tagName, percentSolved);
            }}
            key={tagName}
          >
            <div class="w-full p-4">
              <a
                href="#"
                class="block relative overflow-hidden rounded-2xl bg-gray-100 dark:bg-gray-800 p-6 transition-all duration-300 hover:bg-yellow-400 dark:hover:bg-indigo-500 min-h-[200px] sm:min-h-[250px] lg:min-h-[300px] group"
              >
                <div class="absolute top-0 right-0 w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-yellow-400 dark:bg-indigo-500 rounded-full transform translate-x-1/2 -translate-y-1/2 transition-transform duration-300 group-hover:scale-150"></div>

                <h3 class="text-gray-900 dark:text-white text-xl sm:text-2xl lg:text-2xl font-bold mb-4 z-10 relative">
                  {tagName}
                </h3>

                <p class="text-gray-900 dark:text-white text-md sm:text-lg font-medium z-10 relative">
                  {percentSolved == 100
                    ? "Completed"
                    : percentSolved == 70
                      ? "Just a few more steps"
                      : percentSolved == 0
                        ? "Start now"
                        : "Keep going"}
                </p>

                <div class="mt-4 relative z-10">
                  <ProgressBar
                    progressBarCSS={CardProgressBarCSS}
                    width={[percentSolved]}
                  />
                </div>
              </a>
            </div>
          </NavLink>
        </>
      ) :
        tagName === "C++ BASICS" || tagName === "C++ STL" ? (
          <NavLink
            to="/solve"
            onClick={() => {
              saveTag(tagName, 0);
            }}
            key={tagName}
          >
            <div class="w-full p-4">
              <a
                href="#"
                class="block relative overflow-hidden rounded-2xl bg-gray-100 dark:bg-gray-800 p-6 transition-all duration-300 hover:bg-yellow-400 dark:hover:bg-indigo-500 min-h-[200px] sm:min-h-[250px] lg:min-h-[300px] group"
              >
                <div class="absolute top-0 right-0 w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-yellow-400 dark:bg-indigo-500 rounded-full transform translate-x-1/2 -translate-y-1/2 transition-transform duration-300 group-hover:scale-150"></div>

                <h3 class="text-gray-900 dark:text-white text-xl sm:text-2xl lg:text-2xl font-bold mb-4 z-10 relative">
                  {tagName}
                </h3>
              </a>
            </div>
          </NavLink>
        ) : (

          ///////////////////////////////////
          <NavLink
            to="/login"
            onClick={() => {
              saveTag(tagName, 0);
            }}
            key={tagName}
          >

            <div class="w-full p-4">

              <a
                href="#"
                class="block relative overflow-hidden rounded-2xl bg-gray-100 dark:bg-gray-800 p-6 transition-all duration-300 hover:bg-yellow-400 dark:hover:bg-indigo-500 min-h-[200px] sm:min-h-[250px] lg:min-h-[300px] group"
              >

                <div class="absolute top-0 right-0 w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-yellow-400 dark:bg-indigo-500 rounded-full transform translate-x-1/2 -translate-y-1/2 transition-transform duration-300 group-hover:scale-150"></div>

                <h3 class="text-gray-900 dark:text-white text-xl sm:text-2xl lg:text-2xl font-bold mb-4 z-10 relative">
                  {tagName}
                </h3>

                <h2 className="text-blue dark:text-blue text-xl sm:text-xl lg:text-xl font-bold mb-4 z-10 relative">WelCome Back !</h2>
                <h2 className="text-skyblue dark:text-teal-300 text-xl sm:text-xl lg:text-xl font-bold mb-4 z-10 relative"> Please Login to View</h2>
              </a>

            </div>
          </NavLink>


        )}
    </>
  );
};

export default LearnCard;
