import { React} from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Admin = () => {
	const navigate = useNavigate();
	const userData = useSelector((state) => state.user.user);

	//Redirect user if they're not Admin
	if (!userData.isAdmin) {
		navigate("/");
	}

	return (
		<>
			<div className="h-screen bg-blue2 dark:bg-dark">
				<h1 className="text-blue dark:bg-dark  bg-blue2 text-center font-bold text-4xl pt-[130px] md:text-5xl dark:text-darkBlue ">
					Hey, <span className="text-darkBlue dark:text-blue">Admin</span>
				</h1>
				<section className=" bg-blue2 px-[5%] md:px-[10%] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-14 md:gap-10 gap-5 py-10 lg:py-[50px] dark:bg-dark">
					<NavLink
						to="/addquestions"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-solid fa-plus text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Add Questions
						</p>
					</NavLink>
					<NavLink
						to="/addtag"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-solid fa-hashtag text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Add New Tag
						</p>
					</NavLink>
					<NavLink
						to="/EditQuestion"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-regular fa-pen-to-square text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Edit a Question
						</p>
					</NavLink>
					<NavLink
						to="/PauseBuy"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							
							<i className="fa-solid fa-pause text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Pause Enroll
						</p>
					</NavLink>
					<NavLink
						to="/AddCompany"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-solid fa-hashtag text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Add New Company
						</p>
					</NavLink>
					<NavLink
						to="/AddOAQuestions"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-solid fa-plus text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Add OA Question
						</p>
					</NavLink>
					<NavLink
						to="/EditOAQuestion"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-regular fa-pen-to-square text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Edit OA Question
						</p>
					</NavLink>
					<NavLink
						to="/admin/AddContest"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-regular fa-pen-to-square text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Add Contest
						</p>
					</NavLink>
					<NavLink
						to="/Blog-Admin"
						className="bg-blue5 px-5 py-5 lg:py-10 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
					>
						<div className="bg-blue3 p-5 rounded-lg lg:p-[25%] dark:bg-blue">
							<i className="fa-solid fa-hashtag text-6xl text-darkBlue"></i>
						</div>
						<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
							Edit Blog
						</p>
					</NavLink>
				</section>
			</div>
		</>
	);
};

export default Admin;