import React, { useState, useEffect } from 'react'
import playIcon from "../../assets/play.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchQuestions } from "../../redux/slices/FetchQuestionsSlice";
import { fetchUser } from "../../redux/slices/userSlice";
import ProgressBar from "../../components/ProgressBar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import conceptsPlaylistData from "../../data/ConceptsPlaylist";
import { fetchProblems } from '../../redux/slices/Potd';
import Video from "../../assets/video.png";

const WHATSAPP_GROUP_LINK = "https://chat.whatsapp.com/HUwWZaokuyOGGNRSgGMOpz"

const Potd = (props) => {
    const [visitor_count, set_visitor_count] = useState(null);
    const update_count = async () => {
        if (!visitor_count) {
            await axios.post("/updateCount", {}).then((res) => {
                set_visitor_count(res.data.count);
            });
        }
    }
    update_count();


    const [potd, setPotd] = useState([]);
    const [potdq, setpotdq] = useState([]);
    const [todaysproblem, setTodaysproblem] = useState({});
    // console.log(todaysproblem)
    const currentTime = new Date();
    const options = { timeZone: 'Asia/Kolkata', hour12: false, hour: 'numeric' };
    const indianHour = currentTime.toLocaleString('en-US', options);
    const time = parseInt(indianHour, 10);
    // console.log((time));

    const userData = useSelector((state) => state.user.user); //Fetching userData


    useEffect(() => {
        const fetchQuestionLinks = async () => {
            // Fetch your question links here from the database using an API or function
            const fetchedLink = await axios("/potd");
            setPotd(fetchedLink.data.currentLink);
        }
        fetchQuestionLinks(); // Call the function to start fetching links
    }, []);

    // console.log(potdq)

    const dispatch = useDispatch();
    const location = useLocation();
    // console.log(location)

    const questions = location.state
    // console.log(questions);
    const solvedQuestions = useSelector(
        (state) => state.user.user.solvedQuestions
    );
    const bookmarkedQuestions = useSelector(
        (state) => state.user.user.bookmarkedQuestions
    );
    const userID = useSelector((state) => state.user.user._id);


    useEffect(() => {
        // dispatch(fetchProblems());
        setTodaysproblem(questions[0]);
    }, [dispatch]);


    // <-------- Mark the question in DB ---------->

    const MarkTheQuestion = async (isSolved) => {
        await axios
            .patch("/questions", {
                quesID: curEle._id,
                userID: userID,
                solve: isSolved,
                UpdateSolve: true,
                // tags: curEleTag,
            })
            .then((res) => {
                if (res.data) {
                    isSolved
                        ? toast.info("Question unmarked!")
                        : toast.info("Question saved!");
                    dispatch(fetchUser(res.data)); //Updating the User Data
                } else {
                    console.log("Updated user not received");
                    toast.error("something went wrong!");
                }
            });

    };

    const isSolved = (ele) => {
        if (solvedQuestions) {
            if (
                solvedQuestions.filter((id) => id.question_id === ele._id).length !== 0
            ) {
                return true;
            } else {
                return false;
            }
        }
    };
    // <-------- BookMark the question in DB ---------->

    const isBookMarked = (ele) => {
        if (bookmarkedQuestions) {
            if (
                bookmarkedQuestions.filter((id) => id.includes(ele?._id)).length !== 0
            ) {
                return true;
            } else {
                return false;
            }
        }
    };

    const BookMarkQuestion = async (isBookmarked) => {

        await axios
            .patch("/questions", {
                quesID: curEle._id,
                userID: userID,
                bookmark: isBookmarked,
            })
            .then((res) => {
                if (res.data) {
                    isBookmarked
                        ? toast.info("Question removed from bookmark!")
                        : toast.info("Question bookmarked!");
                    dispatch(fetchUser(res.data)); //Updating the User Data
                } else {
                    toast.error("something went wrong!");
                    console.log("Updated user not received");
                }
            });

    };
    // delete the potd
    const handleDelete = async () => {
        try {
            // Assuming you have the questionId available in your question object
            const questionId = curEle._id;

            // Make a DELETE request to the server to delete the question
            await axios.delete(`/delete-question/${questionId}`);

            // Optionally, update your component state or perform any additional actions
        } catch (error) {
            console.error('Error deleting question:', error);
            // Handle errors or show a notification to the user
        }
    };



    // <-------- activeQuestion CSS Handler ---------->

    const activeTitleClassCSS =
        "bg-blue5 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-blue5  dark:bg-blue dark:group-hover:bg-blue";
    const nonActiveTitleClassCSS =
        "bg-blue7 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-blue5  dark:bg-dark3 dark:group-hover:bg-blue";
    // For Green Color 
    const ActiveSolvedTitleClassCSS =
        "bg-green-400 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-green-400  dark:bg-green-400 dark:group-hover:bg-green-400";
    const nonActiveSolvedTitleClassCSS =
        "bg-green-500 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-green-400  dark:bg-green-500 dark:group-hover:bg-green-400";


    const activeIconClassCSS =
        "bg-blue5 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px]  dark:bg-blue ease-in-out duration-200 group-hover:bg-blue5 dark:group-hover:bg-blue";
    const nonActiveIconClassCSS =
        "bg-blue7 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-dark3 ease-in-out duration-200 group-hover:bg-blue5 dark:group-hover:bg-blue";
    //For Green Color
    const ActiveSolvedIconClassCSS =
        "bg-green-400 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-green-400 ease-in-out duration-200 group-hover:bg-green-400 dark:group-hover:bg-green-400";
    const nonActiveSolvedIconClassCSS =
        "bg-green-500 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-green-500 ease-in-out duration-200 group-hover:bg-green-400 dark:group-hover:bg-green-400";


    const solvedButtonCSS = {
        text: "text-white dark:text-white text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white",
        icon: "fa-solid dark:text-white fa-check text-white group-hover:text-white dark:group-hover:text-white",
        div: "group cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-2 h-10 w-[100px] lg:w-[120px] hover:bg-blue dark:bg-blue dark:hover:bg-blue  ease-in-out duration-200 ",
        bookmarkeDiv:
            "group cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[140px] lg:w-[180px] hover:bg-blue dark:bg-blue dark:hover:bg-blue  ease-in-out duration-200",
        bookmarkedIcon:
            "fa-solid fa-bookmark text-white group-hover:text-white dark:group-hover:text-white",
    };


    // console.log(todaysproblem);
    const [curEle, setCurEle] = useState()
    const [curIdx, setCurIdx] = useState();
    const activeQuestion = (ele, idx, e) => {
        setCurIdx(idx);
        setCurEle(ele);
        // setCurEleTag(ele.tags);
    };

    const f = () => {
        setCurEle(todaysproblem);
    }
    return (

        <div>
            <section className="bg-blue w-screen h-[60%] fixed lg:h-screen z-5 lg:w-[35%] p-5 dark:bg-dark2">
                <div

                    onClick={f}

                    className=" bg-blue7 p-3 rounded-lg flex flex-col items-center justify-center mt-[70px] dark:bg-dark3">

                    <h1 className="text-white text-lg lg:text-2xl font-semibold mb-3">
                        <p >
                            Today's   Problem
                        </p>
                    </h1>
                    <br />
                    <h1 className="text-white text-lg lg:text-2xl font-semibold mb-3">
                        <p>
                            {todaysproblem?.title}
                        </p>
                    </h1>

                </div>
                <div className="mt-5 flex flex-col gap-3 h-[55%] lg:h-[72%] overflow-y-auto">
                    {questions &&
                        questions.map((ele, idx) => {
                            // console.log(idx)
                            if (idx === 0) {
                                return null; // Skips rendering for the first element
                            }
                            return (
                                <div
                                    key={idx}
                                    className="flex gap-4 justify-between items-center group"
                                >
                                    <div
                                        className={
                                            idx === curIdx
                                                ? (ele && isSolved(ele)) ? ActiveSolvedIconClassCSS
                                                    : activeIconClassCSS
                                                : (ele && isSolved(ele)) ? nonActiveSolvedIconClassCSS
                                                    : nonActiveIconClassCSS
                                        }
                                    >
                                        {ele && isSolved(ele) ? (
                                            <i className=" text-white fa-solid fa-check"></i>
                                        ) : isBookMarked(ele) ? (
                                            <i className=" fa-solid fa-bookmark text-white"></i>
                                        ) : idx === curIdx ? (
                                            <i className=" text-white fa-solid fa-angles-right"></i>
                                        ) : (
                                            <i className=" text-white fa-solid fa-code"></i>
                                        )}
                                    </div>
                                    <div
                                        onClick={(e) => {
                                            activeQuestion(ele, idx);
                                        }}
                                        className={
                                            idx === curIdx
                                                ? (ele && isSolved(ele)) ? ActiveSolvedTitleClassCSS
                                                    : activeTitleClassCSS
                                                : (ele && isSolved(ele)) ? nonActiveSolvedTitleClassCSS
                                                    : nonActiveTitleClassCSS
                                        }
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                                    >
                                        <p className="pointer-events-none text-white lg:text-md text-sm font-medium">
                                            {ele.title}
                                        </p>
                                        {ele.video && <img src={Video} style={{ height: "36px" }} alt='Video Exists' />}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </section>
            {curEle ? (

                <section className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark">
                    <div className="flex items-center justify-between mt-6 lg:mt-[90px] w-[100%]">
                        <div className="w-[100%] px-[10%] flex flex-col items-center justify-center gap-5 lg:gap-14 mt-[10px]">
                            <h1 className="text-blue text-xl mt-[20px] lg:text-2xl font-semibold lg:mb-[-30px]">
                                {curEle?.title}
                            </h1>
                            <div className="flex flex-col gap-6 items-center justify-center border-4 py-[12%] md:py-[7%] md:px-[25%] lg:px-[45%] lg:py-[23%] px-[45%] rounded-xl border-blue relative ">
                                {curEle ?
                                    curEle === todaysproblem && curEle.video ?
                                        (time === 23 || time === 1) ?
                                            (<iframe
                                                className="absolute top-0 left-0 w-[100%] h-[100%] p-5"
                                                title="YouTube video player"
                                                src={curEle.video}
                                                allowfullscreen="allowfullscreen"
                                            ></iframe>)

                                            :
                                            (<div className="absolute flex flex-col">
                                                <img
                                                    className="h-[30px] lg:h-[80px] mb-2 lg:mb-5"
                                                    src={playIcon}
                                                    alt="playIcon"
                                                />
                                                <p className="text-blue text-sm lg:text-xl font-semibold">
                                                    Solution will be available at 11 PM IST.
                                                </p>
                                            </div>)

                                        : (
                                            curEle.video ?
                                                (<iframe
                                                    className="absolute top-0 left-0 w-[100%] h-[100%] p-5"
                                                    title="YouTube video player"
                                                    src={curEle.video}
                                                    allowfullscreen="allowfullscreen"
                                                ></iframe>)

                                                :
                                                (<div className="absolute flex flex-col">
                                                    <img
                                                        className="h-[30px] lg:h-[80px] mb-2 lg:mb-5"
                                                        src={playIcon}
                                                        alt="playIcon"
                                                    />
                                                    <p className="text-blue text-sm lg:text-xl font-semibold">
                                                        Solution will be available at 11 PM IST.
                                                    </p>
                                                </div>)
                                        )


                                    : null}

                            </div>
                            <div className=" flex flex- items-center justify-center gap-2 lg:gap-10 ">
                                <div
                                    onClick={() => BookMarkQuestion(isBookMarked(curEle))}
                                    className={
                                        solvedQuestions && isBookMarked(curEle)
                                            ? solvedButtonCSS.bookmarkeDiv
                                            : "group cursor-pointer bg-blue2 border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[120px] lg:w-[160px] hover:bg-blue dark:bg-dark dark:hover:bg-blue  ease-in-out duration-200 "
                                    }
                                >
                                    <p
                                        className={
                                            solvedQuestions && isBookMarked(curEle)
                                                ? solvedButtonCSS.text
                                                : "text-blue text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white"
                                        }
                                    >
                                        {solvedQuestions && isBookMarked(curEle)
                                            ? "Bookmarked"
                                            : "Bookmark"}
                                    </p>
                                    <i
                                        className={
                                            solvedQuestions && isBookMarked(curEle)
                                                ? solvedButtonCSS.bookmarkedIcon
                                                : "fa-regular fa-bookmark text-blue group-hover:text-white dark:group-hover:text-white"
                                        }
                                    ></i>
                                </div>

                                <div
                                    onClick={() => MarkTheQuestion(isSolved(curEle))}
                                    className={
                                        solvedQuestions && isSolved(curEle)
                                            ? solvedButtonCSS.div
                                            : "group cursor-pointer bg-blue2 border-blue border-2 rounded-md flex items-center justify-center gap-2 h-10 w-[100px] lg:w-[120px] hover:bg-blue dark:bg-dark dark:hover:bg-blue  ease-in-out duration-200 "
                                    }
                                >
                                    <p
                                        className={
                                            solvedQuestions && isSolved(curEle)
                                                ? solvedButtonCSS.text
                                                : "text-blue text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white"
                                        }
                                    >
                                        {solvedQuestions && isSolved(curEle)
                                            ? "Solved"
                                            : "Mark"}
                                    </p>
                                    <i
                                        className={
                                            solvedQuestions && isSolved(curEle)
                                                ? solvedButtonCSS.icon
                                                : "fa-solid fa-check text-blue group-hover:text-white dark:group-hover:text-white"
                                        }
                                    ></i>
                                </div>

                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={curEle && curEle.link}
                                    className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[100px] lg:w-[120px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                                >
                                    <p className="text-white text-sm lg:text-lg font-medium">
                                        Solve
                                    </p>
                                    <i className="fa-solid fa-link text-white "></i>
                                </a>



                                < a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={WHATSAPP_GROUP_LINK}
                                    className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                                >
                                    <p className="text-white text-sm lg:text-lg font-medium">
                                        Ask query
                                    </p>
                                    <i className="fa-solid fa-link text-white "></i>
                                </a>

                            </div>
                            {userData && userData.isAdmin ? (
                                <div>
                                    < a
                                        target="_blank"
                                        rel="noreferrer"
                                        href='/'
                                        className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
                                    >
                                        <button onClick={handleDelete} className="text-white text-sm lg:text-lg font-medium">
                                            Delete Potd
                                        </button>
                                    </a>
                                </div>
                            ) : (
                                ""
                            )}


                        </div>
                    </div>

                    {/* <div className="flex items-center justify-between mb-[100px]"></div> */}
                </section>
            ) : (
                <section className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark">
                    <h1 className="text-white text-center text-lg lg:text-3xl font-bold mt-[7%] lg:mt-[25%] p-10 rounded-xl bg-blue dark:bg-dark3 dark:text-blue ">
                        Select a Question <br /> to continue!
                    </h1>
                </section>
            )}

        </div>
    )
}

export default Potd
