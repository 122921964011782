import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const EditOAQuestion = () => {
	const navigate = useNavigate();
	const [OAquesLink, setOAQuesLink] = useState("");
	const [companyData, setCompanyData] = useState();
	const [newCompany, setNewCompany] = useState([]);
	const [oldCompanyData, setOldCompanyData] = useState([]);
	const [oldDifficulty, setOldDifficulty] = useState('');
	const [newQuesData, setNewQuesData] = useState({
		title: "",
		link: "",
		company: [],
		difficulty: "Easy",
		video: "",
	});
	const userData = useSelector((state) => state.user.user);
	//Redirect user if they're not Admin
	if (!userData.isAdmin) {
		navigate("/");
	}

	// <------------ Fetch Question from DB ------------>

	const setOAQuesLinkData = (e) => {
		setOAQuesLink(e.target.value);
	};

	const fetchOAQuesData = async () => {
		const response = await axios.post("/editOAquestion", {
			link: OAquesLink,
		});

		setNewQuesData({
			id: response.data[0]._id,
			difficulty: response.data[0].difficulty,
			link: response.data[0].link,
			company: response.data[0].company,
			title: response.data[0].title,
			video: response.data[0].video,
		});

		setOldCompanyData(response.data[0].company);
		setOldDifficulty(response.data[0].difficulty);
		const companyResponse = await axios.get("/company");
		setCompanyData(companyResponse.data.title);
	};

	//For multiple checkboxes (Company)
	const getNewCompany = (e) => {
		//Destructuring
		const { value, checked } = e.target;
		if (checked) {
			//user checks the box
			setNewCompany([...newCompany, value]);
		} else {
			//user uncheck the company
			setNewCompany(newCompany.filter((e) => e !== value));
		}
	};

	let setOAInputData = (e) => {
		// set Questions data
		setNewQuesData({
			...newQuesData,
			[e.target.name]: e.target.value,
		});

	};

	useEffect(() => {
		// set updated Company
		setNewQuesData({
			...newQuesData,
			company: newCompany,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newCompany])

	// <------------ Update Question to DB ------------>

	const handleOAQuesData = async () => { // On Submit
		try {
			await axios.patch('/editOAquestion', { newQuesData });
			// navigate("/admin");
			toast.success("Question edited successfully!");
		} catch (error) {
			console.log(error);
		}
	};
	// <------------ Delete Question from DB ------------>
	const deleteOAQuestion = async () => {
		try {
			await axios.put('/editOAquestion', { data: OAquesLink });
			navigate("/admin");
			toast.success("Question deleted successfully!");

		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<div className="py-[5%] px-[5%] bg-blue2 dark:bg-dark">
				<h1 className="text-blue dark:bg-dark  bg-blue2 text-center font-bold text-4xl pt-[30px] md:text-4xl dark:text-white ">
					Edit a Question
				</h1>
				<div className="mt-[50px] flex items-center gap-5 justify-center">
					<input
						onChange={setOAQuesLinkData}
						value={OAquesLink}
						className="text-md font-medium p-3 rounded-md w-[550px] "
						placeholder="Enter question link"
					></input>

					<button
						onClick={fetchOAQuesData}
						className="font-medium border-2 border-blue6 rounded-sm py-2.5 px-10 bg-blue6 text-white hover:text-white hover:border-black hover:bg-black ease-in-out duration-300 dark:bg-blue dark:border-blue dark:hover:bg-dark dark:hover:text-blue"
					>
						Edit
					</button>
					<button
						onClick={deleteOAQuestion}
						className="font-medium border-2 border-red-600 rounded-sm py-2.5 px-10 bg-red-600 text-white hover:text-white hover:border-black hover:bg-black ease-in-out duration-300 dark:bg-red-600 dark:border-red-600 dark:hover:bg-dark dark:hover:text-red-600"
					>
						Delete
					</button>
				</div>

				<div className="mx-[10%] mt-[50px] flex flex-col items-center gap-5 justify-center">
					<div className="flex  justify-left items-center gap-5 ">
						<p className="text-darkBlue dark:bg-dark  bg-blue2 text-center font-medium text-md md:text-xl dark:text-white">
							Question Title:
						</p>
						<input
							name="title"
							onChange={setOAInputData}
							value={newQuesData && newQuesData.title}
							className=" w-[500px] text-md font-medium p-3 rounded-md"
						></input>
					</div>
					<div className="flex justify-left items-center gap-5 ">
						<p className="text-darkBlue dark:bg-dark  bg-blue2 text-center font-medium text-md md:text-xl dark:text-white">
							Video Link:
						</p>
						<input
							name="video"
							onChange={setOAInputData}
							value={newQuesData && newQuesData.video}
							className=" w-[500px] text-md font-medium p-3 rounded-md"
						></input>
					</div>

					<div className="mt-5 flex gap-5 items-center justify-center">
						<label
							className="font-medium text-xl text-dark dark:text-white mr-2 "
							htmlFor="difficulty"
						>
							Choose a difficulty:
						</label>

						<select
							className="font-medium text-md p-2"
							onChange={setOAInputData}
							name="difficulty"
						>
							<option value="Easy">Easy</option>
							<option value="Medium">Medium</option>
							<option value="Hard">Hard</option>
							<option value="Ninja">Ninja</option>
						</select>
						<p className="font-semibold text-xl text-dark dark:text-white">
							old difficulty: {oldDifficulty}
						</p>
					</div>

					<button
						onClick={handleOAQuesData}
						className="font-semibold flex items-center justify-center border-2 border-blue6 rounded-sm py-2.5 px-10 mt-10 mx-auto bg-blue6 text-white hover:text-white hover:border-black hover:bg-black ease-in-out duration-300 dark:bg-blue dark:border-blue dark:hover:bg-dark dark:hover:text-blue"
					>
						Update Question
					</button>

					<div className="mx-[10%] mt-5 flex flex-wrap gap-5">
						<div>
							<p className="font-semibold text-xl text-dark dark:text-white">
								Old Company:
							</p>
							{oldCompanyData &&
								oldCompanyData.map((ele, idx) => {
									return (
										<p
											className="font-medium text-md text-dark dark:text-white"
											key={idx}
										>
											{ele}
										</p>
									);
								})}
						</div>
						<div>
							<p className="font-semibold text-xl text-dark dark:text-white">
								Select New Company:
							</p>
							{companyData &&
								companyData.map((ele, key) => {
									return (
										<div key={key}>
											<input
												className="mr-2 scale-150"
												onChange={(e) => getNewCompany(e)}
												id={ele}
												type="checkbox"
												name={ele}
												value={ele}
											/>
											<label
												className="font-medium text-md text-dark dark:text-white "
												htmlFor={ele}
											>
												{ele}
											</label>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditOAQuestion;
