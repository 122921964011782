import { configureStore } from "@reduxjs/toolkit";
import userReducer from './slices/userSlice';
import sendQuestionsReducer from './slices/SendQuestionsSlice'
import fetchQuestionsReducer from './slices/FetchQuestionsSlice'
import allProgressReducer from './slices/AllProgressSlice'
import tagsProgressReducer from './slices/TagsProgressSlice'

import sendOAQuestionsReducer from './slices/SendOAQuestionsSlice'
import fetchOAQuestionsReducer from './slices/FetchOAQuestionsSlice'
import allOArogressReducer from './slices/AllOAProgressSlice'
import CompanyProgressReducer from './slices/CompanyProgressSlice'
import potd from './slices/Potd'


export const store = configureStore({
    reducer: {
        user: userReducer,
        questions: sendQuestionsReducer,
        CurrentQuestions: fetchQuestionsReducer,
        AllProgress: allProgressReducer,
        TagsProgress: tagsProgressReducer,
        OA_questions: sendOAQuestionsReducer,
        CurrentOAQuestions: fetchOAQuestionsReducer,
        allOAProgress: allOArogressReducer,
        companyProgress: CompanyProgressReducer,
        Potd:potd,
    }
});