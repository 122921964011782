import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const allOAProgress = createAsyncThunk('allOAProgress', async (userID)=>{
    const response = await axios.get(`/allOAprogress/${userID}`);
    await window.localStorage.setItem('ALL_OA_PERCENT', JSON.stringify(response.data));
    return response.data;
})

const allOAProgressSlice = createSlice({
    name: 'allOAProgress',
    initialState: {
        isLoading: false,
        data: '',
        isError: false
    },
})

export default allOAProgressSlice.reducer;