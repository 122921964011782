import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendQuestions = createAsyncThunk('sendQuestions', async (data)=>{
    // console.log(data); 
    const response = await axios.post('/questions',{
        questions: data.questions,
        tags: data.tags,
        difficulty: data.difficulty,
        likes: 0,
        dislikes: 0,
    }
    );
    // console.log(response);
    return response.json;
})

const sendQuestionsSlice = createSlice({
    name: 'questions',
    initialState: {
        isLoading: false,
        data:null,
        isError: false
    },
    extraReducers: (builder)=>{
        builder.addCase(sendQuestions.pending, (state, action)=>{
            state.isLoading = true;
        })
        builder.addCase(sendQuestions.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.data = action.payload;
        })
        builder.addCase(sendQuestions.rejected, (state, action)=>{
            console.log('Error', action.payload);
            state.isError = true;
        })
    }
})

export default sendQuestionsSlice.reducer;