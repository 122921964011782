import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { sendQuestions } from "../../redux/slices/SendQuestionsSlice.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const AddQuestions = () => {
	const navigate = useNavigate();
	const userData = useSelector((state) => state.user.user);

	//Redirect user if they're not Admin
	if (!userData.isAdmin) {
		navigate("/");
	}
	// <-------- Fetch Tags from DB ---------->
	const [tagData, setTagData] = useState();

	useEffect(() => {
		(async function () {
			try {
				const response = await axios.get("/tags");
				setTagData(response.data.title);
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	const dispatch = useDispatch();
	const [tags, setTags] = useState([]);

	const [quesData, setQuesData] = useState({
		questions: "",
		tags: [],
		difficulty: "Easy",
	});

	let setInputData = (e) => {
		// set Questions links
		setQuesData({
			...quesData,
			[e.target.name]: e.target.value,
		});
		// console.log(quesData);
	};

	//For multiple checkboxes (Tags)
	const getTags = (e) => {
		//Destructuring
		const { value, checked } = e.target;
		if (checked) {
			//user checks the box
			setTags([...tags, value]);
		} else {
			//user uncheck the tags
			setTags(tags.filter((e) => e !== value));
		}
	};

	useEffect(() => {
		//Update main Question data object before on Submit
		setQuesData({
			...quesData,
			tags: tags,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tags]);

	const handleQuesData = () => {
		// On Submit
		dispatch(sendQuestions(quesData));
		navigate("/admin");
		toast.success("Question added successfully!");
	};

	return (
		<div className="py-[6%] px-[5%] bg-blue2 dark:bg-dark">
			<h1 className="text-blue dark:bg-dark  bg-blue2 text-center font-bold text-4xl md:text-4xl dark:text-white ">
				Add Questions
			</h1>
			<div className="mt-[20px] flex flex-col items-center justify-center">
				<div className="flex flex-col justify-center items-center">
					<label className="text-darkBlue dark:bg-dark  bg-blue2 text-center font-medium text-md md:text-xl dark:text-white mb-5">
						Enter questions links
					</label>
					<textarea
						onChange={setInputData}
						value={quesData.questions}
						className="max-w-full text-md font-medium p-5 rounded-md"
						name="questions"
						rows="4"
						cols="50"
					></textarea>
				</div>

				<div className="mx-[10%] mt-5 flex flex-wrap gap-5">
					{tagData &&
						tagData.map((ele, key) => {
							return (
								<div key={key}>
									<input
										className="mr-2 scale-150"
										onChange={(e) => getTags(e)}
										id={ele}
										type="checkbox"
										name={ele}
										value={ele}
									/>
									<label
										className="font-medium text-md text-dark dark:text-white "
										htmlFor={ele}
									>
										{ele}
									</label>
								</div>
							);
						})}
				</div>

				<div className="mt-5">
					<label
						className="font-medium text-xl text-dark dark:text-white mr-2 "
						htmlFor="difficulty"
					>
						Choose a difficulty:
					</label>
					<select
						className="font-medium text-md p-2"
						onChange={setInputData}
						name="difficulty"
					>
						<option value="Easy">Easy</option>
						<option value="Medium">Medium</option>
						<option value="Hard">Hard</option>
						<option value="Ninja">Ninja</option>
					</select>
				</div>
				<button
					onClick={handleQuesData}
					className="font-medium flex items-center justify-center border-2 border-blue6 rounded-sm py-2.5 px-10 mt-10 mx-auto w-[20%] bg-blue6 text-white hover:text-white hover:border-black hover:bg-black ease-in-out duration-300 dark:bg-blue dark:border-blue dark:hover:bg-dark dark:hover:text-blue"
				>
					Add Questions
				</button>
			</div>
		</div>
	);
};

export default AddQuestions;
