import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const companyProgress = createAsyncThunk('companyProgress', async (userID)=>{
    const response = await axios.get(`/companyprogress/${userID}`);
    window.localStorage.setItem('ALL_COMPANY_PERCENT', JSON.stringify(response.data));
    return response.data;
})

const companyProgressSlice = createSlice({
    name: 'companyProgress',
    initialState: {
        isLoading: false,
        data: '',
        isError: false
    },
})
export default companyProgressSlice.reducer;