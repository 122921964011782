import { React, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchOAQuestions } from "../../redux/slices/FetchOAQuestionsSlice";
import { fetchUser } from "../../redux/slices/userSlice";
import ProgressBar from "../../components/ProgressBar";
import playIcon from "../../assets/play.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import conceptsPlaylistData from "../../data/ConceptsPlaylist";
import Video from "../../assets/video.png";


const WHATSAPP_GROUP_LINK = "https://chat.whatsapp.com/HUwWZaokuyOGGNRSgGMOpz"

const OA_Solve = () => {
	const [visitor_count, set_visitor_count] = useState(null);
	const update_count = async () => {
		if (!visitor_count) {
			await axios.post("/updateCount", {}).then((res) => {
				set_visitor_count(res.data.count);
			});
		}
	}
	update_count();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const questions = useSelector((state) => state.CurrentOAQuestions.data);
	const solvedOAQuestions = useSelector(
		(state) => state.user.user.solvedOAQuestions
	);
	const bookmarkedQuestions = useSelector(
		(state) => state.user.user.bookmarkedQuestions
	);
	const userID = useSelector((state) => state.user.user._id);
	const userData = useSelector((state) => state.user.user)

	const data = window.localStorage.getItem("SELECTED_COMPANY_DATA"); //Fetching Company from localstorage
	const companyData = JSON.parse(data);
	const currentCompanyName = companyData.name;
	const currentCompanyPercent = companyData.percent;
	let currentConceptPlaylistLink = "";

	try {
		currentConceptPlaylistLink = conceptsPlaylistData[currentCompanyName];
	} catch (error) {
		console.log(error);
	}

	console.log(currentCompanyName, currentConceptPlaylistLink);

	useEffect(() => {
		dispatch(fetchOAQuestions(currentCompanyName));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCompanyName]);

	// <-------- Mark the question in DB ---------->

	const MarkTheQuestion = async (isSolved) => {
		if (!userID) {
			navigate("/login");
		} else {
			await axios
				.patch("/OA_questions", {
					quesID: curEle._id,
					userID: userID,
					solve: isSolved,
					UpdateSolve: true,
					company: curEleCompany,
					date_solved: new Date().toISOString().split('T')[0],
					difficulty: curEle.difficulty,
				})
				.then((res) => {
					if (res.data) {
						isSolved
							? toast.info("Question unmarked!")
							: toast.info("Question saved!");
						dispatch(fetchUser(res.data)); //Updating the User Data
					} else {
						console.log("Updated user not received");
						toast.error("something went wrong!");
					}
				});
		}
	};

	const isSolved = (ele) => {
		if (solvedOAQuestions) {
			if (
				solvedOAQuestions.filter((id) => id.question_id === ele._id).length !== 0
			) {
				return true;
			} else {
				return false;
			}
		}
	};
	// <-------- BookMark the question in DB ---------->

	const isBookMarked = (ele) => {
		if (bookmarkedQuestions) {
			if (
				bookmarkedQuestions.filter((id) => id.includes(ele._id)).length !== 0
			) {
				return true;
			} else {
				return false;
			}
		}
	};

	// <-------- activeQuestion CSS Handler ---------->

	const activeTitleClassCSS =
		"bg-blue5 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-blue5  dark:bg-blue dark:group-hover:bg-blue";
	const nonActiveTitleClassCSS =
		"bg-blue7 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-blue5  dark:bg-dark3 dark:group-hover:bg-blue";
	const ActiveSolvedTitleClassCSS =
		"bg-green-400 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-green-400  dark:bg-green-400 dark:group-hover:bg-green-400";
	const nonActiveSolvedTitleClassCSS =
		"bg-green-500 rounded-md flex items-center px-3.5 w-[90%] h-[40px] cursor-pointer ease-in-out duration-200 group-hover:bg-green-400  dark:bg-green-500 dark:group-hover:bg-green-400";

	const activeIconClassCSS =
		"bg-blue5 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px]  dark:bg-blue ease-in-out duration-200 group-hover:bg-blue5 dark:group-hover:bg-blue";
	const nonActiveIconClassCSS =
		"bg-blue7 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-dark3 ease-in-out duration-200 group-hover:bg-blue5 dark:group-hover:bg-blue";
	const ActiveSolvedIconClassCSS =
		"bg-green-400 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-green-400 ease-in-out duration-200 group-hover:bg-green-400 dark:group-hover:bg-green-400";
	const nonActiveSolvedIconClassCSS =
		"bg-green-500 rounded-md flex justify-center items-center px-3.5 h-[40px] w-[50px] dark:bg-green-500 ease-in-out duration-200 group-hover:bg-green-400 dark:group-hover:bg-green-400";

	const solvedButtonCSS = {
		text: "text-white dark:text-white text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white",
		icon: "fa-solid dark:text-white fa-check text-white group-hover:text-white dark:group-hover:text-white",
		div: "group cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-2 h-10 w-[100px] lg:w-[120px] hover:bg-blue dark:bg-blue dark:hover:bg-blue  ease-in-out duration-200 ",
		bookmarkeDiv:
			"group cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[140px] lg:w-[180px] hover:bg-blue dark:bg-blue dark:hover:bg-blue  ease-in-out duration-200",
		bookmarkedIcon:
			"fa-solid fa-bookmark text-white group-hover:text-white dark:group-hover:text-white",
	};

	const [curIdx, setCurIdx] = useState();
	const [curEle, setCurEle] = useState();
	const [curEleCompany, setCurEleCompany] = useState();

	const activeQuestion = (ele, idx, e) => {
		setCurIdx(idx);
		setCurEle(ele);
		setCurEleCompany(ele.company);
	};

	// <---------------------------------------------->

	const progressBarCSS = {
		bgLine: "bg-white px-1 h-4 dark:bg-darkBlue",
		frontLine: "bg-blue7 h-2.5 dark:bg-blue2",
		text: "text-white text-sm lg:text-md font-medium dark:text-blue2",
		width: 80,
	};
	function f() {
		console.log(curEle)
		axios.post("/pre-potd", {
			curPotd: curEle
		}).then(response => {
			console.log("success");
		})
			.catch(error => {
				console.log("error");
			})
	}
	
	useEffect(() => {
		const getViews = async () => {
		  try {
			const data = { view: 1, quesID: curEle._id, name: userID };
			const res = await axios.patch("/OA_questions", data).then((res)=>{
				dispatch(fetchOAQuestions(currentCompanyName));
			});
			console.log(res.data.msg);
		  } catch (err) {
			console.error("Error is :", err);
		  }
		}
		getViews();
	  }, [userID,curEle?._id]); 
	  console.log(curEle?._id);
	
	  const convert_Small = (value)=>{
		if(value<=999)return value;
		else return  Number.parseFloat(value/1000).toFixed(2)+ "k"
	  }

	return (
		<>
			{questions.length !== 0 ? (
				<>
					{/* <-------------- SideBar --------------> */}
					<section className="bg-blue w-screen h-[60%]  fixed lg:h-screen z-5 lg:w-[35%] p-5 dark:bg-dark2">
						<div className=" bg-blue7 p-3 rounded-lg flex flex-col items-center justify-center mt-[70px] dark:bg-dark3">
							<h1 className="text-white text-lg lg:text-2xl font-semibold mb-3">
								{currentCompanyName}
							</h1>
							<div className="w-[80%]">
								<ProgressBar
									width={currentCompanyPercent}
									progressBarCSS={progressBarCSS}
								/>
							</div>
						</div>

						<div className="mt-5 flex flex-col gap-3 h-[55%] lg:h-[72%] overflow-y-auto">
							{questions &&
								questions.map((ele, idx) => {
									return (
										<div
											key={idx}
											className="flex gap-4 justify-between items-center group"
										>
											<div
												className={
													idx === curIdx
														? (ele && isSolved(ele)) ? ActiveSolvedIconClassCSS
															: activeIconClassCSS
														: (ele && isSolved(ele)) ? nonActiveSolvedIconClassCSS
															: nonActiveIconClassCSS
												}
											>
												{ele && isSolved(ele) ? (
													<i className=" text-white fa-solid fa-check"></i>
												) : isBookMarked(ele) ? (
													<i className=" fa-solid fa-bookmark text-white"></i>
												) : idx === curIdx ? (
													<i className=" text-white fa-solid fa-angles-right"></i>
												) : (
													<i className=" text-white fa-solid fa-code"></i>
												)}
											</div>
											<div
												onClick={(e) => {
													activeQuestion(ele, idx);
												}}
												className={
													idx === curIdx
														? (ele && isSolved(ele)) ? ActiveSolvedTitleClassCSS
															: activeTitleClassCSS
														: (ele && isSolved(ele)) ? nonActiveSolvedTitleClassCSS
															: nonActiveTitleClassCSS
												}
												style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
											>
												<p className="pointer-events-none text-white lg:text-md text-sm font-medium">
													{ele.title}
												</p>
												<div class="flex justify-between items-center">
                            						<i class="fas fa-eye mr-1 text-white"></i>
                            						<p class="mr-2 text-white">{convert_Small(ele.views.length)}</p>
												{ele.video && <img src={Video} style={{ height: "36px" }} alt="Video Solution" />}
											    </div>
											</div>
										</div>
									);
								})}
						</div>
					</section>
					{/* <-------------- Main Section --------------> */}
					{curEle ? (
						<section className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark">
							<div className="flex items-center justify-between mt-6 lg:mt-[90px] w-[100%]">
								<div className="w-[100%] px-[10%] flex flex-col items-center justify-center gap-5 lg:gap-14 mt-[10px]">
									<div className=" flex flex- items-center w-[80%] mt-[20px] lg:mb-[-30px]">
										<h1 className="mx-auto text-blue text-xl lg:text-2xl font-semibold ">
											{curEle.title}
										</h1>
									</div>
									{/* ------------- Video Player ----------------- */}
									<div className="flex flex-col gap-6 items-center justify-center border-4 py-[12%] md:py-[7%] md:px-[25%] lg:px-[45%] lg:py-[23%] px-[45%] rounded-xl border-blue relative ">
										{curEle ? (
											curEle.video ? (
												<iframe
													className="absolute top-0 left-0 w-[100%] h-[100%] p-5"
													title="YouTube video player"
													src={curEle.video}
													allowfullscreen="allowfullscreen"
												></iframe>
											) : (
												<div className="absolute flex flex-col">
													<img
														className="h-[30px] lg:h-[80px] mb-2 lg:mb-5"
														src={playIcon}
														alt="playIcon"
													/>
													<p className="text-blue text-sm lg:text-xl font-semibold">
														Video tutorial coming soon...
													</p>
												</div>
											)
										) : null}
									</div>

									<div className=" flex flex- items-center justify-center gap-2 lg:gap-10 ">
										<div
											onClick={() => MarkTheQuestion(isSolved(curEle))}
											className={
												solvedOAQuestions && isSolved(curEle)
													? solvedButtonCSS.div
													: "group cursor-pointer bg-blue2 border-blue border-2 rounded-md flex items-center justify-center gap-2 h-10 w-[100px] lg:w-[120px] hover:bg-blue dark:bg-dark dark:hover:bg-blue  ease-in-out duration-200 "
											}
										>
											<p
												className={
													solvedOAQuestions && isSolved(curEle)
														? solvedButtonCSS.text
														: "text-blue text-sm lg:text-lg font-medium group-hover:text-white dark:group-hover:text-white"
												}
											>
												{solvedOAQuestions && isSolved(curEle)
													? "Solved"
													: "Mark"}
											</p>
											<i
												className={
													solvedOAQuestions && isSolved(curEle)
														? solvedButtonCSS.icon
														: "fa-solid fa-check text-blue group-hover:text-white dark:group-hover:text-white"
												}
											></i>
										</div>

										<a
											target="_blank"
											rel="noreferrer"
											href={curEle && curEle.link}
											className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[100px] lg:w-[120px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
										>
											<p className="text-white text-sm lg:text-lg font-medium">
												Solve
											</p>
											<i className="fa-solid fa-link text-white "></i>
										</a>

										< a
											target="_blank"
											rel="noreferrer"
											href={WHATSAPP_GROUP_LINK}
											className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
										>
											<p className="text-white text-sm lg:text-lg font-medium">
												Ask query
											</p>
											<i className="fa-solid fa-link text-white "></i>
										</a>



									</div>
									{userData && userData.isAdmin ? (
										<div>
											< a
												target="_blank"
												rel="noreferrer"
												href="/"
												className=" cursor-pointer bg-blue border-blue border-2 rounded-md flex items-center justify-center gap-3 h-10 w-[150px] lg:w-[150px] dark:bg-blue hover:bg-blue7 hover:border-blue7  dark:hover:bg-blue7 dark:hover:border-blue7 hover:scale-105 ease-in-out duration-200"
											>
												<button onClick={f} className="text-white text-sm lg:text-lg font-medium">
													Add POTD
												</button>
											</a>
										</div>
									) : (
										""
									)}

								</div>
							</div>

							{/* <div className="flex items-center justify-between mb-[100px]"></div> */}
						</section>
					) : (
						<section className="bg-blue2 lg:h-screen fixed top-[60%] w-screen h-[40%] lg:top-0 lg:right-[0%] lg:w-[65%] z-5 lg:z-10 flex flex-col items-center justify-between dark:bg-dark">
							<h1 className="text-white text-center text-lg lg:text-3xl font-bold mt-[7%] lg:mt-[25%] p-10 rounded-xl bg-blue dark:bg-dark3 dark:text-blue ">
								Select a Question <br /> to continue!
							</h1>
						</section>
					)}
				</>
			) : (
				<div className="flex items-center justify-center bg-blue2 h-screen w-screen dark:bg-dark">
					<Loader />
				</div>
			)
			}
		</>
	);
};

export default OA_Solve;
