// LinkModal.jsx

import React from 'react';

const LinkModal = ({ isOpen, onClose, setNewLink, addLink, newLink }) => {
  if (!isOpen) return null;

  const handleClose = (e) => {
    // Check if the click occurred on the close button or the wrapper
    if (e.target.classList.contains('close-button') || e.target.id === 'wrapper') {
      onClose();
    }
  };

  return (
    <div className='fixed inset-0 flex justify-center items-center' id='wrapper' onClick={handleClose}>
      <div className='fixed inset-0 bg-black bg-opacity-25'></div>
      <div className='relative z-10 bg-white p-6 rounded' onClick={(e) => e.stopPropagation()}>
        <button className='absolute top-2 right-2 text-black text-xl close-button' onClick={onClose}>
          X
        </button>
        <div>
          <div>
            <label htmlFor="linkInput">Add Link:</label>
            <input
              id="linkInput"
              type="text"
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
              style={{ border: '1px solid black' }}
            />
            <button onClick={addLink}>Add Link</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;
