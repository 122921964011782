import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import playIcon from "../assets/play.svg"


const VideoModal = ({ closeModal, contestPlatform, contestId, questionNo,props }) => {
  const [link, setLink] = useState('https://www.youtube.com/embed/9dOdVUctAi0?si=0zGT78hiBECvryvT');

  useEffect(() => {
    const fetchVideoLink = async () => {
      try {
        const response = await axios.get(`/getVideo?contestPlatform=${contestPlatform}&contestId=${contestId}&questionNo=${questionNo}`);

        const fetchedLink = response.data.video;
        // console.log(response.data.video)
        if (!fetchedLink) {
          toast.info('No link available for this question.');
          setLink("empty");
        } else {
          setLink(fetchedLink);
          console.log(link)
        }
      } catch (error) {
        console.error('Error fetching link:', error);
        setLink("empty");
      }
    };

    fetchVideoLink();

    return () => {
    };
  }, [contestPlatform, contestId, questionNo]); // Add dependencies


  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-white p-8 h-1/2 max-w-2xl max-h-screen w-full relative rounded">
        <span
          className="absolute top-2 right-2 text-gray-700 cursor-pointer"
          onClick={closeModal}
        >
          &times;
        </span>
        {link!=="empty" ? (
          <iframe
            title="YouTube Video"
            width="560"
            height="450"
            src={link}
            // src='https://www.youtube.com/embed/9dOdVUctAi0?si=0zGT78hiBECvryvT\'
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="w-full h-full"
          ></iframe>
        ) : (
          <div className=" flex flex-col gap-6 items-center justify-center border-4 py-[12%] md:py-[35%] md:px-[25%] lg:px-[45%] lg:py-[35%] px-[45%] rounded-xl dark:bg-dark3 relative ">
          <div className="absolute flex flex-col">
          <img
            className="h-[30px] lg:h-[80px] mb-2 lg:mb-5"
            src={playIcon}
            alt="playIcon"
          />
          <p className="text-blue text-sm lg:text-xl font-semibold">
            Video tutorial coming soon...
          </p>
        </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default VideoModal;
