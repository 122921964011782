import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ShowContests from '../../components/ShowContests';
import 'react-toastify/dist/ReactToastify.css';

const AddEditorial = (props) => {
    const [selectedLinkButton, setSelectedLinkButton] = useState('');

    const handleLinkButtonClick = (buttonName) => {
        setSelectedLinkButton((prevSelectedButton) =>
            prevSelectedButton === buttonName ? '' : buttonName
        );
    };

    return (
        <div className={props.theme === "dark" ? "dark:bg-dark3 text-white min-h-screen" : "bg-blue2 text-white min-h-screen"}>
            <div className="container mx-auto py-8">
                <h1 className={`${props.theme === "dark" ? "text-white" : "text-blue"} text-3xl md:text-5xl text-center font-bold mb-8`}>
                    Select Platform
                </h1>
                <div className="mt-4 pt-4 pb-6 grid grid-cols-2 gap-4">
                    <button
                        className={` ${props.theme === "dark" ? "text-white" : "text-black"} bg-blue hover:bg-green-700 font-bold py-2 px-4 rounded-full transition duration-300 ${selectedLinkButton === 'Codechef' ? 'bg-green-700' : ''}`}
                        onClick={() => handleLinkButtonClick('Codechef')}
                    >
                        Codechef
                    </button>
                    <button
                        className={`${props.theme === "dark" ? "text-white" : "text-black"}  bg-blue hover:bg-green-700 font-bold  py-2 px-4 rounded-full transition duration-300 ${selectedLinkButton === 'Codeforces' ? 'bg-green-700' : ''}`}
                        onClick={() => handleLinkButtonClick('Codeforces')}
                    >
                        Codeforces
                    </button>
                </div>
            </div>
            <ShowContests platform={selectedLinkButton} props={props} theme={props.theme} />
        </div >
    );
};

export default AddEditorial;
