import React from 'react';

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === 'wrapper' || e.target.classList.contains('close-button')) {
      onClose();
    }
  };

  return (
    <div className='fixed inset-0 flex justify-center items-center' id='wrapper' onClick={handleClose}>
      <div className='fixed inset-0 bg-black bg-opacity-25'></div>
      <div className='relative z-10 bg-white p-6 rounded w-1/2 h-3/4 max-h-[75%] max-w-[50%] overflow-y-auto'>
        <button className='absolute top-2 right-2 text-black text-xl close-button' onClick={handleClose}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
