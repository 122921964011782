import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const AddTag = () => {
    const navigate = useNavigate();
	const userData = useSelector((state) => state.user.user);

	//Redirect user if they're not Admin
	if (!userData.isAdmin) {
		navigate("/");
	}
	const [tag, setTag] = useState("");

	const setTagData = (e) => {
		setTag(e.target.value);
	};

	const handleSubmit = async () => {
        const response = await axios.post("/tags", {
            tag: tag.toUpperCase(),
		})
        navigate("/admin");
		toast.success("Tag added successfully!");
		return response;
    }
	return (
		<>
			<div className="h-screen px-[5%] bg-blue2 dark:bg-dark">
				<h1 className="text-blue dark:bg-dark  bg-blue2 text-center font-bold text-4xl pt-[130px] md:text-4xl dark:text-white ">
					Add New Tag
				</h1>
				<div className="mt-[50px] flex flex-col items-center justify-center">
					<div className="flex flex-col justify-center items-center">
						<label className="text-darkBlue dark:bg-dark  bg-blue2 text-center font-medium text-md md:text-xl dark:text-white mb-5">
							Enter tag names
						</label>
						<input
							onChange={setTagData}
							value={tag}
							className="max-w-full text-md font-medium p-5 rounded-md"
							name="tag"
						></input>
					</div>
					<button
						onClick={handleSubmit}
						className="font-medium flex items-center justify-center border-2 border-blue6 rounded-sm py-2.5 px-10 mt-10 mx-auto w-[20%] bg-blue6 text-white hover:text-white hover:border-black hover:bg-black ease-in-out duration-300 dark:bg-blue dark:border-blue dark:hover:bg-dark dark:hover:text-blue"
					>
						Add
					</button>
				</div>
			</div>
		</>
	);
};

export default AddTag;
