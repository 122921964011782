import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BeatLoader } from 'react-spinners'; // Import BeatLoader from react-spinners

const ContestList = ({ platform,props }) => {
  const [contestData, setContestData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContests = async () => {
      setLoading(true); // Set loading to true when fetching data
      try {
        const response = await axios.get(
          `/showContests?platform=${platform}`
        );
        setContestData(response.data);
      } catch (error) {
        console.error(error);
        toast.error('An error occurred while fetching contests.');
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };

    fetchContests();
  }, [platform]);

  const handleDeleteContest = async (contestName) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Contest?');

    if (!isConfirmed) {
      return;
    }

    try {
      // Send a request to delete the contest with the given contestName
      const response = await axios.delete(
        '/deleteContests',
        {
          data: { contestName, platform },
        }
      );

      if (response.status === 201) {
        // If deletion is successful, update the local state to reflect the change
        setContestData((prevContests) =>
          prevContests.filter((contest) => contest.contestName !== contestName)
        );

        // Show success message using toastify.js
        toast.success('Contest deleted successfully!');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while deleting the contest.');
    }
  };

  return (
    <div className="flex justify-center">
      <div className='dark:bg-dark3'>
        {loading ? ( // Display loading indicator if loading is true
          <div className="text-center mt-4">
            <BeatLoader color={'#00ff00'} loading={loading} size={15} />
          </div>
        ) : platform === '' ? (
          <div className="bg-white border border-black rounded-md p-4 font-bold">
            Click on Button to See Contests
          </div>
        ) : (
          <div className="flex flex-col">
            {contestData.length === 0 ? (
              <div className="bg-white border border-black rounded-md p-4 font-bold">
                No Contests are there. Please add them above.
              </div>
            ) : (
              contestData.map((contest) => (
                <div key={contest.contestId} className="mb-2">
                  <div className="flex items-center p-4 rounded-xl border border-black bg-white hover:bg-green-300 transition duration-300 ease-in-out ">
                    <p className="flex-grow mr-4 text-black font-bold hover:text-white">{contest.contestName}</p>
                    <button
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={() => handleDeleteContest(contest.contestName)}
                    >
                      Delete
                    </button>
                  </div>
                  {/* Add additional contest details as needed */}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContestList;
