import "../Styles/Loader.css";
import Lottie from "react-lottie";
import animationData from "../assets/load.json";
const Loader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      
    return (
        <div className="loader">
            <h1 className="font-bold">Loading...</h1>
            <div className="loader-dot-parent">
            <Lottie 
      options={defaultOptions}
      height={400} 
      width={400}
      className="object-cover w-full h-64 sm:h-96 dark:bg-gray-900 border-r"
    />
            </div>
        </div>
    );
};

export default Loader;