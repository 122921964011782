import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from './Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit } from 'react-icons/fa';
import katex from "katex";
import "katex/dist/katex.min.css";
import CustomToolbar from './CustomToolbar';
import { useSelector } from 'react-redux';

window.katex = katex;

const QuillEditorTextModal = ({
  isVisible,
  onClose,
  isEditorial,
  initialValue,
  contestId,
  contestPlatform,
  questionNo,
  onSubmit,
  problemName,
}) => {
  const [editorValue, setEditorValue] = useState('');
  const [previousValue, setPreviousValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const userData=useSelector((state)=>state.user.user);

  useEffect(() => {
    if (isEditorial) {
      getEditorialDeltas();
    } else {
      setEditorValue(initialValue || 'No Editorial Added Yet For This Question');
    }
  }, [isEditorial, initialValue]);

  const getEditorialDeltas = async () => {
    try {
      const response = await axios.get('/getEditorial', {
        params: {
          contestPlatform: contestPlatform,
          contestId: contestId,
          questionNo: questionNo,
        },
      });

      const deltas = response.data?.editorial[0] || '';
      setEditorValue(deltas);
      setPreviousValue(deltas);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelClick = () => {
    setEditorValue(previousValue);
    setIsEditing(false);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const saveEditorialData = async (deltas) => {
    try {
      await axios.patch('/updateEditorial', {
        contestPlatform: contestPlatform,
        contestId: contestId,
        questionNo: questionNo,
        deltas,
      });

      onSubmit();
      toast.success('Data submitted successfully!');
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit data. Please try again.');
    }
  };

  const handleEditorModalSubmit = () => {
    saveEditorialData(editorValue);
    setIsEditing(false);
  };

  const handleQuillChange = (value) => {
    console.log(value);
    setEditorValue(value);
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };
  const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
  ];

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div className="p-8 text-center relative">
        <div className="mb-3">
          <h3 className="text-xl font-semibold text-grey-900">{isEditorial ? 'Editorial' : 'Edit Editorial'}</h3>
          <h4 className="text-lg font-semibold mb-2">{problemName}</h4>
        </div>
        <div className="flex justify-end items-start mt-3 absolute top-0 right-0">
  {userData.isAdmin && (
    <>
      {isEditing ? (
        <FaEdit className="cursor-pointer ml-2 text-blue-500" onClick={handleCancelClick} />
      ) : (
        <FaEdit className="cursor-pointer ml-2 text-blue-500" onClick={handleEditClick} />
      )}
      {!isEditing && <span className="text-gray-500 italic ignore-close">Click pencil to edit</span>}
    </>
  )}
</div>
        {isEditing ? (
          <>
            <CustomToolbar />
            <ReactQuill
              value={editorValue}
              onChange={handleQuillChange}
              placeholder={isEditorial ? 'Edit your text here' : 'Enter your text here'}
              modules={modules}
              formats={formats}
              className="quill-editor"
            />
          </>
        ) : (
          <div className="quill-editor" dangerouslySetInnerHTML={{ __html: editorValue }} />
        )}
        {isEditing && (
          <div className="flex justify-end mt-4">
            <button className="text-gray-500 italic mr-4 ignore-close" onClick={handleCancelClick}>
              Cancel
            </button>
            <button
              className="bg-blue-500 text-black py-2 px-4 rounded hover:bg-blue-700"
              onClick={handleEditorModalSubmit}
            >
              {isEditorial ? 'Update Editorial' : 'Submit Editorial'}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QuillEditorTextModal;
