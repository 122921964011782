import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MdDeleteForever } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';
import { VscTriangleRight, VscTriangleDown } from 'react-icons/vsc';
import { IoIosAddCircle } from 'react-icons/io';
import 'tailwindcss/tailwind.css';
import Modal2 from './Modal2'; // Import your Modal2 component
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners';

const TagsSection = ({ contestPlatform, contestId, questionNo }) => {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.user.user);

  useEffect(() => {
    fetchTags();
  }, [contestPlatform, contestId, questionNo]);

  const fetchTags = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/getTags?contestPlatform=${contestPlatform}&contestId=${contestId}&questionNo=${questionNo}`);
      const fetchedTags = response.data.tags;
      if (fetchedTags.length === 0) {
        console.log('No tags available for this question.');
      } else {
        setTags(fetchedTags);
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
    } finally {
      setLoading(false);
    }
  };

  const addTag = async () => {
    try {
      if (newTag.trim() !== '') {
        const trimmedTag = newTag.trim();

        setLoading(true); // Set loading to true when submitting
        await axios.post('/addTags', {
          contestPlatform,
          contestId,
          questionNo,
          text: trimmedTag,
        });

        setTags([...tags, { text: trimmedTag }]);
        setNewTag('');
        toast.success('Tag added successfully');
        setIsAddTagModalOpen(false);
      }
    } catch (error) {
      console.error('Error adding tag:', error);
      toast.error('Error adding tag');
    } finally {
      setLoading(false); // Set loading to false after request completion
    }
  };

  const deleteTag = async (index) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this tag?');

    if (!isConfirmed) {
      return;
    }
    setLoading(true);
    try {
      const tagsCopy = [...tags];
      const deletedTag = tagsCopy.splice(index, 1)[0];

      await axios.delete('/deleteTags', {
        data: {
          contestPlatform,
          contestId,
          questionNo,
          tagsIndex: index,
        },
      });

      setTags(tagsCopy);
      toast.success('Tag deleted successfully');
    } catch (error) {
      console.error('Error deleting tag:', error);
      toast.error('Error deleting tag');
    }
    finally{
      setLoading(false);
    }
  };

  const toggleTags = () => {
    setIsTagsOpen((prev) => !prev);
  };

  const toggleAddTagModal = () => {
    setIsAddTagModalOpen((prev) => !prev);
  };

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <Box
      my={-4}
      p={2}
      border="2px solid grey"
      sx={{
        bgcolor: 'white', maxHeight: '160px', overflowY: 'auto',
        maxWidth: '800px', // Increase the maximum width
        width: '800px',
      }}
      className="flex flex-col items-center"
    >
      <div className="flex items-center mb-4">
        <button onClick={toggleTags}>
          {isTagsOpen ? <VscTriangleDown /> : <VscTriangleRight />}
        </button>
        <span className="font-bold ml-2">Tags</span>
        <div className="ml-4">
          {userData.isAdmin && (
            <button onClick={toggleAddTagModal} className="text-blue-500 mr-2">
              <IoIosAddCircle />
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-2 justify-center">
        {isTagsOpen && (
          tags.length === 0 ? (
            <p>No tags available.</p>
          ) : (
            tags.map((tag, index) => (
              <div key={index} className="border p-2 rounded bg-gray-100 text-black">
                <div className="flex items-center justify-center">
                  <div className="mr-2">
                    {tag.text}
                  </div>
                  {userData.isAdmin && (
                 
                    <button onClick={() => deleteTag(index)} className="text-red-500">
                      <MdDeleteForever />
                    </button>
                   )}
                </div>
              </div>
            ))
          )
        )}
      </div>
      <Modal2 isVisible={isAddTagModalOpen} onClose={toggleAddTagModal}>
        <div>
          <label className='mr-4'>Add Tag:</label>
          <input type="text" value={newTag} onChange={(e) => setNewTag(e.target.value)} className="border border-gray-400 p-1" />
          <button onClick={addTag} className="bg-blue-500 hover:bg-green-300 text-black px-2 py-1 ml-2">Submit</button>
        </div>
      </Modal2>
      {loading && <BeatLoader color={'#00ff00'} loading={loading} css={override} size={15} />}
    </Box>
  );
};

export default TagsSection;
