import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUser = createAsyncThunk("fetchUser", async () => {
	const user = JSON.parse(window.localStorage.getItem('CURRENT_USER_DATA'));
	const userData = {
		email: user.username
	}
	if(userData.email){
		const response = await axios.post("/getuser", {userData});
		return response.data;
	}
    return userData;
});

const fetchUserSlice = createSlice({
	name: "userData",
	initialState: {
        isLoading: false,
        user: [],
        isError: false},
    reducers: {
        resetUser: (state) => {
            state.user = [];
            state.isLoading = false;
            state.isError = false;
          },
    },
	extraReducers: (builder) => {
		builder.addCase(fetchUser.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(fetchUser.fulfilled, (state, action) => {
			state.isLoading = false;
			state.user = action.payload;
		});
		builder.addCase(fetchUser.rejected, (state, action) => {
			console.log("Error", action.payload);
			state.isError = true;
		});
	},
});

export const { resetUser } = fetchUserSlice.actions;
export default fetchUserSlice.reducer;
