import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from './Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToolbar from './CustomToolbar';

const QuillEditorModal = ({ onHintAdded,isVisible, onClose, doEdit, hintNo, initialValue, contestId, contestPlatform, questionNo, setHints, doDelete }) => {

  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    if (doEdit) {
      getHintDeltas();
    } else {
      // Reset editor value when adding a new hint
      setEditorValue(initialValue || ''); // Ensure initialValue is not undefined
    }
  }, [doEdit, initialValue]);

  const getHintDeltas = async () => {
    try {
      const response = await axios.get('/getHints', {
        params: {
          contestPlatform: contestPlatform,
          contestId: contestId,
          questionNo: questionNo,
        },
      });

      const hintDeltas = response.data.hints[hintNo]?.deltas || [];
      const combinedDeltas = hintDeltas.map(delta => JSON.stringify(delta)).join('');

      setEditorValue(combinedDeltas);
    } catch (error) {
      console.error(error);
    }
  };

  const saveEditorialData = async (deltas) => {
    try {
      let response;
      if (!doEdit && hintNo === null) {
        console.log("i am here");

        // Perform a POST request to add hints
        response = await axios.post('/addHints', {
          contestPlatform: contestPlatform,
          contestId: contestId,
          questionNo: questionNo,
          deltas: deltas,
        });
        console.log(response);

        // Check if the response contains updated hint data
      

        // Show success notification
        toast.success('Editorial submitted successfully!');
        
        // Clear the editor content
        setEditorValue('');
        onHintAdded();
        
        // Close the modal
        onClose();
      } else {
        // Perform a PATCH request to update hints
        response = await axios.patch('/updateHints', {
          contestPlatform: contestPlatform,
          contestId: contestId,
          questionNo: questionNo,
          hintId: hintNo,
          deltas: deltas,
        });

       

      

        // Show success notification
        toast.success('Editorial updated successfully!');
        
        // Close the modal
        onHintAdded();
        onClose();
      }
    } catch (error) {
      console.error(error);
      // Show error notification
      toast.error('Failed to submit editorial. Please try again.');
    }
  };

  const handleEditorModalSubmit = () => {
    saveEditorialData(editorValue);
  };

  const handleQuillChange = (value) => {
    setEditorValue(value);
  };
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };
  const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
  ];

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div className='p-6'>
        <h3 className='text-xl font-semibold text-grey-900 mb-5 text-center'>Write Hint</h3>
        <CustomToolbar />
            <ReactQuill
              value={editorValue}
              onChange={handleQuillChange}
              placeholder="Enter your text here"
              modules={modules}
              formats={formats}
              className="quill-editor"
            />


        <div className='flex justify-end mt-4'>
          <button
            className='bg-blue-500 text-black py-2 px-4 rounded hover:bg-blue-700'
            onClick={handleEditorModalSubmit}
          >
            Submit Hint
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default QuillEditorModal;
