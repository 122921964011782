import React from "react";
import loginWaveLight from "../../assets/waves/loginWaveLight.svg";
import loginWaveDark from "../../assets/waves/loginWaveDark.svg";
import { NavLink } from "react-router-dom";
const Error = (props) => {
	return (
		<>
			<section
				className="px-[5%] m flex flex-col gap-5 items-center justify-center h-screen w-screen relative bg-cover bg-no-repeat bg-center" style={props.theme === "dark" ? { backgroundImage: `url(${loginWaveDark})` } : { backgroundImage: `url(${loginWaveLight})`}}
			>
				<div className="flex flex-col items-center justify-center px-[2%] py-[8%] gap-7 drop-shadow-lg rounded-lg bg-blue dark:bg-dark">
					<h1 className="text-white text-center rounded-xl text-xl font-semibold ">
                    If you encounter login problems while using a browser other than Chrome, <br />please attempt to log in using the Chrome browser instead. <br /> we are working on this issue.
					</h1>
					<NavLink
						className="bg-white px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150"
						to='/login'
					>
						<span>Login</span>
					</NavLink>
				</div>
			</section>
		</>
	);
};

export default Error;
