import { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import "../../Styles/Charts/heat-map.css"

const HeatMap = ({ heatMapData }) => {
    const svgRef = useRef();
    const [totalProblems, setTotalProblems] = useState(0);
    useEffect(() => {
        if (!heatMapData || heatMapData.length == 0) return;
        let totalCount = 0;
        const margin = { top: 30, right: 30, bottom: 60, left: 30, between: 30 },
            width = 150 - margin.left - margin.right,
            height = 210 - margin.top - margin.bottom

        const days = ["Sat", "Fri", "Thu", "Wed", "Tue", "Mon", "Sun"]
        const yearData = [{ month: "Jan", daysCount: 31 }, { month: "Feb", daysCount: 29 }, { month: "Mar", daysCount: 31 }, { month: "Apr", daysCount: 30 }, { month: "May", daysCount: 31 }, { month: "Jun", daysCount: 30 }, { month: "Jul", daysCount: 31 }, { month: "Aug", daysCount: 31 }, { month: "Sep", daysCount: 30 }, { month: "Oct", daysCount: 31 }, { month: "Nov", daysCount: 30 }, { month: "Dec", daysCount: 31 }]
        let lastDay = 0;
        let maxSolvedCount = 0;
        for (let i = 0; i < 12; i++) {
            let month = [];
            let currWeek = 1;
            for (let j = 0; j < yearData[i].daysCount; j++) {
                lastDay = lastDay < 6 ? lastDay + 1 : 0;
                month.push({ week: currWeek, day: days[lastDay], value: 0 });
                if (lastDay == 6)
                    currWeek++;

                // maxSolvedCount = Math.max(maxSolvedCount, month[j].value);
                // totalCount += month[j].value;
            }
            if (heatMapData && heatMapData.length > 0) {
                for (let key of Object.entries(heatMapData[i])) {
                    month[heatMapData[i][key[0]]].value = key[1];
                    totalCount += key[1];
                    maxSolvedCount = Math.max(maxSolvedCount, key[1]);
                }
            }

            yearData[i].monthData = month;
        }

        const weeks1 = [1, 2, 3, 4];
        const weeks2 = [1, 2, 3, 4, 5]
        const weeks3 = [1, 2, 3, 4, 5, 6];
        for (let i = 0; i < 12; i++) {
            const maxWeek = yearData[i].monthData[yearData[i].daysCount - 1].week;
            const currWidth = width / 5 * maxWeek;
            const currWeeks = maxWeek == 4 ? weeks1 : maxWeek == 5 ? weeks2 : weeks3;
            const x = d3.scaleBand().range([0, currWidth]).domain(currWeeks).padding(0.1);
            yearData[i].width = currWidth;
            yearData[i].x = x;
        }

        const y = d3.scaleBand()
            .range([height, 0])
            .domain(days)
            .padding(0.1);

        let totalWidth = yearData.reduce((acc, curr) => acc + curr.width + margin.between, 0);
        let sizes = [12, 6, 4, 3, 2, 1];
        let groups = [];
        let i = 0;
        while (totalWidth > window.innerWidth) {
            i++;
            totalWidth = 0;
            for (let j = 0; j < sizes[i]; j++) {
                totalWidth += yearData[j].width + margin.between;
            }
        }
        let groupCount = 12 / sizes[i];
        let groupSize = sizes[i];


        d3.select(svgRef.current).selectAll("*").remove();
        let svg = d3.select(svgRef.current)
            .append("svg")
            .attr("width", totalWidth + margin.right)
            .attr("height", (height + margin.bottom) * groupCount + margin.top)

        let heightTillNow = margin.top;
        for (i = 0; i < groupCount; i++) {
            let widthTillNow = margin.left + 10;
            for (let j = 0; j < groupSize; j++) {
                let currentGroup = svg
                    .append("g")
                    .attr("transform", `translate(${widthTillNow},${heightTillNow})`);

                currentGroup.append("g");
                currentGroup.append("text")
                    .attr("x", (width) / 2)
                    .attr("y", height + 40)
                    .attr("dy", "-1em")
                    .attr("class", "heat-map-month-text")
                    .text(yearData[i * groupSize + j].month);
                if (j == 0)
                    currentGroup.append("g")
                        .call(d3.axisLeft(y));

                groups.push(currentGroup);
                widthTillNow += yearData[i * groupSize + j].width + margin.between;
            }
            heightTillNow += height + margin.bottom;
        }

        let startColor = "white";
        if (document.documentElement.classList.contains("dark")) {
            startColor = "#00112c";
        }
        const myColor = d3.scaleLinear()
            .range([startColor, "#0088ff"])
            .domain([0, 4])

        if (maxSolvedCount == 0) maxSolvedCount = 1;
        for (let i = 0; i < 12; i++) {
            groups[i].select("g").attr("transform", `translate(0, ${height}) scale(1, -1)`).selectAll()
                .data(yearData[i].monthData)
                .enter()
                .append("rect")
                .attr("question-value", function (d) { return d.value })
                .attr("x", function (d) { return yearData[i].x(d.week) })
                .attr("y", function (d) { return y(d.day) })
                .attr("width", yearData[i].x.bandwidth())
                .attr("height", y.bandwidth())
                .style("fill", function (d) { return myColor(Math.ceil(d.value * 4 / maxSolvedCount)) })
        }

        let heatMapLegend = document.getElementById("heat-map-legend");
        svg.selectAll("rect")
            .on("mouseover", function (e, d) {
                d3.select(this).classed("hovered", true);
                heatMapLegend.classList.add("appear");
                findPosition(this, heatMapLegend);
            })
            .on("mouseout", function (e, d) {
                d3.select(this).classed("hovered", false);
                heatMapLegend.classList.remove("appear");
            })

        function findPosition(obj, heatMapLegend) {
            let heatMapContainer = document.getElementById("heat-map-container");
            let containerLeft = heatMapContainer.getBoundingClientRect().left;
            let containerTop = heatMapContainer.getBoundingClientRect().top;
            let objLeft = obj.getBoundingClientRect().left;
            let objTop = obj.getBoundingClientRect().top;

            let x = objLeft - containerLeft;
            let y = objTop - containerTop;

            if (x + 200 > heatMapContainer.offsetWidth) {
                x = x - 200;
            }
            heatMapLegend.style.left = x + 10 + "px";
            heatMapLegend.style.top = y - 40 + "px";
            heatMapLegend.innerHTML = obj.getAttribute("question-value") + " questions Solved";
        }

        setTotalProblems(totalCount);
    }, [heatMapData]);
    return (
        <div id="heat-map-container" >
            <h1> HeatMap</h1>
            <div className="heat-map-options">
                <h1>Total Problems Solved : {totalProblems}</h1>
                <select>
                    <option value="year">2024</option>
                </select>
            </div>
            <div ref={svgRef} id="heat-map-svg"></div>
            <div id="heat-map-legend"></div>
        </div>
    )
}

export default HeatMap;