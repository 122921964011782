import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';
import "../../Styles/Charts/pie-chart.css"

const PieChart = ({ data }) => {
    const svgRef = useRef();
    const [sliceData, setSliceData] = useState([]);
    const [pieChartData, setPieChartData] = useState({
        width: window.innerWidth > 768 ? window.innerWidth * 0.45 : window.innerWidth * 0.8,
        height: window.innerWidth > 768 ? window.innerWidth * 0.45 : window.innerWidth * 0.8,
        outerCircleRadius: window.innerWidth > 768 ? window.innerWidth / 5 : window.innerWidth * 0.35,
        innerCircleRadius: window.innerWidth > 768 ? window.innerWidth / 10 : window.innerWidth * 0.17
    })

    useEffect(() => {
        if (!data || Object.keys(data).length == 0) return;
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();
        const pie = d3.pie().value(d => d[1]);
        const slices = pie(Object.entries(data));
        const arc = d3.arc().innerRadius(0).outerRadius(pieChartData.outerCircleRadius);

        // use color scheme that gives more than 10 color
        svg.selectAll('path')
            .data(slices)
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', (d, i) => d3.schemeCategory10[i])
            .attr("class", "slice")
            .attr('opacity', 0)
            .attr('name', d => d.data[0])
            .transition()
            .duration(500)
            .ease(d3.easeLinear)
            .attr('opacity', 1)
            .delay(d => d.index * 200);

        svg.append('circle').attr('cx', pieChartData.width / 2).attr('cy', pieChartData.height / 2).attr('r', pieChartData.innerCircleRadius).attr('class', 'inner-circle');


        svg.selectAll('text')
            .data(slices)
            .enter()
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('font-weight', 'bold')
            .attr('name', d => d.data[0])
            .attr('class', 'slice-text')
            .text(d => `${d.data[0]}: ${d.data[1]}`);

        const paths = svg.selectAll('path');
        const texts = svg.selectAll('text');
        paths.on('mouseover', (e, d) => {
            paths.filter(':hover').classed('hovered', true);
            paths.filter(':not(:hover)').classed('hovered', false);
            texts.filter((e, i) => e.index == d.index).classed('hovered', true);
            texts.filter((e, i) => e.index != d.index).classed('hovered', false);
            paths.filter(':not(:hover)').classed('fade', true);
        }).on('mouseout', (e, d) => {
            paths.classed('hovered', false);
            texts.classed('hovered', false);
            const selected = paths.nodes().some(el => el.classList.contains('selected'));
            if (!selected)
                paths.classed('fade', false);
        })

        function findIndexByKey(arr, key) {
            return arr.findIndex(subArr => subArr[0] === key);
        }

        if (data && Object.keys(data).length > 0) {
            let temp = [];
            for (let key of Object.entries(data)) {
                let index = findIndexByKey(Object.entries(data), key[0]);
                temp.push({ name: key[0], value: key[1], color: d3.schemeCategory10[index] })
            }
            setSliceData([...temp]);
        }

        const slices2 = document.querySelectorAll('.slice, .slice-text');
        if (slices2 && slices2.length > 0) {
            slices2.forEach(slice => {
                slice.style.setProperty('--transform-value', pieChartData.width / 2 + 'px');
            })
        }
        return () => {
            svg.selectAll('*').remove();
        }

    }, [data, pieChartData]);

    useEffect(() => {
        window.addEventListener("resize", reSize);
    }, [])

    function reSize() {
        pieChartData.width = window.innerWidth > 768 ? window.innerWidth * 0.45 : window.innerWidth * 0.8;
        pieChartData.height = pieChartData.width;
        pieChartData.outerCircleRadius = pieChartData.width / 2 * 0.85;
        pieChartData.innerCircleRadius = pieChartData.outerCircleRadius / 2;
        setPieChartData({ ...pieChartData });
    }

    return (
        <div className='pie-chart' >
            <h1>Problems By Tag</h1>
            <div className='chart-data'>
                <svg ref={svgRef} width={pieChartData.width} height={pieChartData.height} ></svg>
                <div className="slice-data-container">
                    {sliceData.length > 0 && sliceData.map((slice, index) => (
                        <div key={index} className="slice-data">
                            <div className="slice-color" style={{ backgroundColor: slice.color }}></div>
                            <div> {slice.value}: {slice.name} </div>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default PieChart;