import React, { useState } from "react";
import axios from "axios";
import {  ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../../Firebase'; // Your Firebase config file
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill CSS
import CodeMirror from '@uiw/react-codemirror';
import { oneDark } from '@codemirror/theme-one-dark';

const Create = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [exampleCode, setExampleCode] = useState("");
  const [code, setCode] = useState("");
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  // Function to handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the image is selected
    if (!image) {
      alert("Please select an image!");
      return;
    }
    
    setUploading(true);

    // Firebase Storage reference
    const storageRef = ref(storage, `images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    // Track the upload progress and get the download URL
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Upload error:", error);
        setUploading(false);
        alert("Error uploading image.");
      },
      async () => {
        // Once the upload is complete, get the image URL
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("File available at", downloadURL);
          
          // Prepare form data
          const blogData = {
            title,
            author,
            content,
            description,
            imageUrl: downloadURL,
            exampleCode,
            code
          };

          // Submit the form data to your API endpoint
          await axios.post("/api/blogs", blogData);
          alert("Blog created successfully");

          // Reset form fields
          setTitle("");
          setAuthor("");
          setContent("");
          setDescription("");
          setImage(null);
          setCode("");
          setExampleCode("");
        } catch (error) {
          console.error("Error creating blog:", error);
          alert("Error creating blog.");
        } finally {
          setUploading(false);
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-8 dark:bg-gray-800 bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
  <div className="form-group">
    <label htmlFor="title" className="block text-gray-700 dark:text-gray-200 text-lg font-semibold mb-2">Title</label>
    <input
      id="title"
      type="text"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      placeholder="Write your title"
      className="w-full p-3 border border-gray-300 rounded-lg text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
      required
    />
  </div>

  <div className="form-group">
    <label htmlFor="author" className="block text-gray-700 dark:text-gray-200 text-lg font-semibold mb-2">Author</label>
    <input
      id="author"
      type="text"
      value={author}
      onChange={(e) => setAuthor(e.target.value)}
      placeholder="Author name"
      className="w-full p-3 border border-gray-300 rounded-lg text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
      required
    />
  </div>

  <div className="form-group">
    <label htmlFor="image" className="block text-gray-700 dark:text-gray-200 text-lg font-semibold mb-2">Image</label>
    <input
      id="image"
      type="file"
      onChange={(e) => {
        if (e.target.files && e.target.files.length > 0) {
          setImage(e.target.files[0]);
        }
      }}
      className="w-full text-gray-700 dark:text-gray-200 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
    />
  </div>

  <div className="form-group">
    <label htmlFor="description" className="block text-gray-700 dark:text-gray-200 text-lg font-semibold mb-2">Description</label>
    <input
      id="description"
      type="text"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      placeholder="Describe here"
      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 text-black"
      required
    />
  </div>

  <div className="form-group">
    <label htmlFor="content" className="block text-gray-700 dark:text-gray-200 text-lg font-semibold mb-2">Content</label>
    <ReactQuill
      id="content"
      value={content}
      onChange={setContent}
      className="text-black dark:text-white"
      placeholder="Write the content here. You can also paste images and text!"
      style={{ minHeight: '200px', marginBottom: '20px', backgroundColor: 'white', color: 'black' }}
      modules={{
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['link', 'image'],
          ['clean']
        ],
      }}
    />
  </div>

  <div className="form-group">
    <label htmlFor="codeTopic" className="block text-gray-700 dark:text-gray-200 text-lg font-semibold mb-2">Code Topic</label>
    <input
      id="codeTopic"
      type="text"
      value={exampleCode}
      onChange={(e) => setExampleCode(e.target.value)}
      placeholder="Code topic"
      className="w-full p-3 border border-gray-300 text-black rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
      
    />
  </div>

  <CodeMirror
  value={code}
  theme={oneDark}
  onChange={(value) => setCode(value)} // Use 'value' directly here
  height="200px"
  className="w-full border border-gray-300 text-black rounded-lg shadow-sm"
  style={{
    borderRadius: '4px',
    marginBottom: '20px',
    fontSize: '14px'
  }}
/>

  <button
    type="submit"
    className="w-full py-3 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
    disabled={uploading}
  >
    {uploading ? "Uploading..." : "Submit"}
  </button>
</form>

  );
};

export default Create;
