import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContestList from '../../components/ContestList';
import { BeatLoader } from 'react-spinners';

const AddContest = (props) => {
    const [selectedLinkButton, setSelectedLinkButton] = useState('');
    const [showContestButton, setShowContestButton] = useState('');
    const [link, setLink] = useState('');
    const [codechefContests, setCodechefContests] = useState([]);
    const [codeforcesContests, setCodeforcesContests] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleLinkButtonClick = (buttonName) => {
        setSelectedLinkButton((prevSelectedButton) => prevSelectedButton === buttonName ? '' : buttonName);
    };

    const handleLinkButtonClick2 = (buttonName) => {
        setShowContestButton((prevSelectedButton) => prevSelectedButton === buttonName ? '' : buttonName);
    };

    const handleLinkChange = (event) => {
        setLink(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            if (link.trim() !== '' && selectedLinkButton.trim() !== '') {
                setLoading(true);

                const response = await axios.post('/addUrl', {
                    contestId: link,
                    Platform: selectedLinkButton,
                });

                if (response.status === 200) {
                    toast.success('Data submitted successfully!');
                } else {
                    toast.error('Failed to submit data. Please try again.');
                }

                setLink('');
                setSelectedLinkButton('');

                const codechefResponse = await axios.get('/showContests?platform=Codechef');
                const codeforcesResponse = await axios.get('/showContests?platform=Codeforces');

                setCodechefContests(codechefResponse.data);
                setCodeforcesContests(codeforcesResponse.data);
            } else {
                toast.warning('Please provide both ContestId and select a platform before submitting.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while submitting data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={props.theme === "dark" ? "dark:bg-dark3 min-h-screen" : "bg-blue2  min-h-screen"}>
            {loading && (
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-75 z-50">
                    <BeatLoader color={'#00ff00'} loading={loading} size={15} />
                </div>
            )}
            <div>
                <h1 className="text-blue font-bold text-3xl md:text-5xl dark:text-white text-center pt-4 pb-6">
                    Add Contest
                </h1>
            </div>

            <center>
                <input
                    className="w-2/3 md:w-1/3 h-10 p-2 border border-black rounded-md focus:outline-none focus:border-blue-500"
                    type="text"
                    placeholder="ContestId..."
                    value={link}
                    onChange={handleLinkChange}
                    style={{ marginRight: '10px' }}
                />
            </center>

            <div className="mt-4 pt-4 pb-6 flex justify-center">
                <button
                    className="w-1/3 md:w-1/6 mr-2 bg-green-300 hover:bg-green-400 transition duration-300 ease-in-out text-black font-bold border border-gray-300 rounded-md py-2 px-4"
                    onClick={() => handleLinkButtonClick('Codechef')}>
                    Codechef
                </button>
                <button
                    className="w-1/3 md:w-1/6 ml-2 bg-green-300 hover:bg-green-400 transition duration-300 ease-in-outtext-black font-bold border border-gray-300 rounded-md py-2 px-4"
                    onClick={() => handleLinkButtonClick('Codeforces')}>
                    Codeforces
                </button>
            </div>

            <center className="text-bold mt-4 pt-4">
                <span className=' bg-white border border-black rounded-md p-4 font-bold pb-3 mb-3'>
                    Selected Platform: {selectedLinkButton}
                </span>
            </center>

            <center>
                <button className="mt-4 py-2 px-4 border border-green-300 bg-green-300 hover:bg-green-400 text-black rounded-md" onClick={handleSubmit}>Submit</button>
            </center>

            <div>
                <h1 className="text-blue font-bold text-5xl md:text-5xl dark:text-white text-center pt-4 pb-6">
                    Added Contests
                </h1>
            </div>

            <div className="mt-4 pt-4 pb-6 flex justify-center">
                <button
                    className="w-1/3 md:w-1/6 mr-2 bg-green-300 hover:bg-green-400 transition duration-300 ease-in-outtext-black font-bold border-gray-300 rounded-md py-2 px-4"
                    onClick={() => handleLinkButtonClick2('Codechef')}>
                    Codechef
                </button>
                <button
                    className="w-1/3 md:w-1/6 ml-2 bg-green-300 hover:bg-green-400 transition duration-300 ease-in-outtext-black font-bold border border-gray-300 rounded-md py-2 px-4"
                    onClick={() => handleLinkButtonClick2('Codeforces')}>
                    Codeforces
                </button>
            </div>

            <ContestList platform={showContestButton} props={props} />

        </div>
    );
};

export default AddContest;
