const playlists = {
    "DP": "https://www.youtube.com/playlist?list=PLUpPy1Tkgmu5BDXhAdjYPl7Q7iYY0IJUY",
    "BINARY SEARCH": "https://www.youtube.com/playlist?list=PLUpPy1Tkgmu7Q8ZlhiiXpE2ugHyF1eHR6",
    "SEGMENT TREES AND LAZY PROPAGATION": "https://www.youtube.com/playlist?list=PLUpPy1Tkgmu7bmatmmH8sdCgDI0QT9csq",
    "C++ BASICS": "https://www.youtube.com/playlist?list=PLUpPy1Tkgmu6zCowWUsfZ-lA6yYRI7aqP",
    "C++ STL": "https://www.youtube.com/playlist?list=PLUpPy1Tkgmu6zCowWUsfZ-lA6yYRI7aqP",
    "PREFIX SUMS AND DIFFERENCE ARRAY": "https://youtu.be/ybc2mb48_Kg",
    "BIT MANIPULATION": "https://www.youtube.com/playlist?list=PLUpPy1Tkgmu6l-5TGD0Ti_Ed65TqhTAUC",
    "GRAPHS": "https://www.youtube.com/playlist?list=PLUpPy1Tkgmu6XW2QKV5yIqZcWahOt1UuU",
}

export default playlists;