import aditya from "../assets/profilePics/AdityaDargan.jpg";
import kushagra from "../assets/profilePics/Kushagra Pathak.jpg";
import prabhjot from "../assets/profilePics/Prabhjot Singh.jpg";
import vivek from "../assets/profilePics/Vivek Arora.jpg";
import rishbh from "../assets/profilePics/RishbhSharma.jpg";
import dipankar from "../assets/profilePics/Dipankar Yadav.jpg";
import mulayam from "../assets/profilePics/Mulayam yadav.jpg";
import harsh from "../assets/profilePics/Harsh Sharma.jpg";
import abhay from "../assets/profilePics/AbhaySingh.jpg";
import gurmeet from "../assets/profilePics/Gurmeet Singh.jpg";
import ankit from "../assets/profilePics/Ankit Josh.jpg";

const TeamProfileData = [
	{
		img: aditya,
		name: "Aditya Dargan",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/aditya-dargan-ad2912/",
		codeforces: "1833",
		leetcode: "2189",
	},
	{
		img: kushagra,
		name: "Kushagra Pathak",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/kushagrapathak15/",
		codeforces: "1692",
		leetcode: "2089",
	},
	{
		img: prabhjot,
		name: "Prabhjot Singh",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/prabhxjottsingh",
		codeforces: "1625",
		leetcode: "1992",
	},
	{
		img: vivek,
		name: "Vivek Arora",
		role: "Tutor",
		linkdin: "https://www.linkedin.com/in/vivekarora02",
		codeforces: "1579",
		leetcode: "1901",
	},
	{
		img: rishbh,
		name: "Rishbh Sharma",
		role: "Mentor",
		linkdin: "https://www.linkedin.com/in/rishabh-sharma-8b3a73205/",
		codeforces: "1512",
		leetcode: "1818",
	},
	{
		img: dipankar,
		name: "Dipankar Yadav",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/dipankar-yadav-ba6663157/",
		codeforces: "1498",
		leetcode: "1900",
	},
	{
		img: mulayam,
		name: "Mulayam yadav",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/mulayam13986/",
		codeforces: "1470",
		leetcode: "1991",
	},
	{
		img: harsh,
		name: "Harsh Sharma",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/harshsharma20503",
		codeforces: "1464",
		leetcode: "1937",
	},
	{
		img: abhay,
		name: "Abhay Singh",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/abhay-singh-b825a1221/",
		codeforces: "1443",
		leetcode: "1585",
	},

	{
		img: gurmeet,
		name: "Gurmeet Singh",
		role: "DSA Mentor",
		linkdin: "https://www.linkedin.com/in/gurmeetsingh2003/",
		codeforces: "1368",
		leetcode: "2114",
	},

	{
		img: ankit,
		name: "Ankit Josh",
		role: "Teaching Assistant",
		linkdin: "https://www.linkedin.com/in/ankitjosh78",
		codeforces: "1282",
		leetcode: "1810",
	},
];

export default TeamProfileData;
