import React, { useEffect, useState } from 'react';
import axios from 'axios';

export function Update() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDelete = async (id) => {
    try {
      // Make DELETE request to the backend
      await axios.delete(`/api/blogs/${id}`);
      
      // Update the blogs state to remove the deleted blog
      setBlogs(blogs.filter((blog) => blog.id !== id));
      alert("blog deleted. Please Refresh page");
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // const response = await axios.get('http://localhost:8080/api/blogs');
        const response = await axios.get('/api/blogs');
        setBlogs(response.data);
      } catch (error) {
        setError('Error fetching blogs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <p>Loading blogs...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="flex min-h-screen w-full flex-col bg-gray-200 dark:text-gray-900">
    <div className="flex flex-col gap-4 py-4 pl-14">
      <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
        <div>
          <h1 className="text-2xl font-semibold mb-2">Blog Details</h1>
          <p className="text-gray-700">Blogs details will be shown below</p>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
              <tr className="bg-gray-100 border-b border-gray-300">
                <th className="py-3 px-4 text-left text-gray-600">Image</th>
                <th className="py-3 px-4 text-left text-gray-600">Title</th>
                <th className="py-3 px-4 text-left text-gray-600">Id</th>
                <th className="py-3 px-4 text-left text-gray-600">Views</th>
                <th className="py-3 px-4 text-left text-gray-600">Reacts</th>
                <th className="py-3 px-4 text-left text-gray-600">Date</th>
                <th className="py-3 px-4 text-left text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog) => (
                <tr key={blog.id} className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="py-4 px-4">
                    <img
                      alt={blog.title}
                      className="w-16 h-16 rounded-md object-cover"
                      src={blog.image} 
                    />
                  </td>
                  <td className="py-4 px-4 font-medium">{blog.title}</td>
                  <td className="py-4 px-4">
                    <span className="border rounded px-2 py-1">{blog.id}</span>
                  </td>
                  <td className="py-4 px-4">{blog.views || 'N/A'}</td>
                  <td className="py-4 px-4">{blog.reacts || 'N/A'}</td>
                  <td className="py-4 px-4">{blog.createdAt || 'N/A'}</td>
                  <td className="py-4 px-4">
                    <button
                      onClick={() => handleDelete(blog._id)}
                      className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  </div>
  );
}

export default Update;


